import React from "react";
import Header from "../../Common/default/Header";
import Footer from "../../Common/default/Footer";
import Banner from "../../Elements/default/Banner";
// import GoogleMapReact from 'google-map-react';

var bnrimg = "/assets/media/images/banner/banner.jpg";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

class ContactUs extends React.Component {
  render() {
    // const defaultProps = {
    //     center: {
    //       lat: 13.115269939588897,
    //       lng: 77.57452325396984,
    //     },
    //     zoom: 12
    // };
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Connecting with you, committed to excellence!"
            pagename="Contact Us"
            bgimage={bnrimg}
          />

          {/* SECTION CONTENTG START */}
          <div className="section-full p-tb80 inner-page-padding">
            {/* LOCATION BLOCK*/}
            <div className="container">
              {/* GOOGLE MAP & CONTACT FORM */}
              <div className="section-content">
                {/* CONTACT FORM*/}
                <div className="row">
                  <div className="col-md-8 col-sm-6">
                    <form
                      className="contact-form cons-contact-form"
                      method="post"
                      action="form-handler.php"
                    >
                      <div className="contact-one m-b30">
                        {/* TITLE START */}
                        <div className="section-head">
                          <div className="mt-separator-outer separator-left">
                            <div className="mt-separator">
                              <h2 className="text-uppercase sep-line-one ">
                                <span className="font-weight-300 text-primary">
                                  Get
                                </span>{" "}
                                In touch
                              </h2>
                            </div>
                          </div>
                        </div>
                        {/* TITLE END */}
                        <div className="form-group">
                          <input
                            name="username"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            name="email"
                            type="text"
                            className="form-control"
                            required
                            placeholder="Email"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            name="phone"
                            type="text"
                            className="form-control"
                            required
                            placeholder="Phone"
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            name="message"
                            rows={4}
                            className="form-control "
                            required
                            placeholder="Message"
                            defaultValue={""}
                          />
                        </div>
                        <div className="text-right">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button btn-effect"
                          >
                            submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="contact-info m-b30">
                      {/* TITLE START */}
                      <div className="section-head">
                        <div className="mt-separator-outer separator-left">
                          <div className="mt-separator">
                            <h2 className="text-uppercase sep-line-one ">
                              <span className="font-weight-300 text-primary">
                                Contact
                              </span>{" "}
                              Info
                            </h2>
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <div className="bg-dark p-a20 text-white">
                        <div className="mt-icon-box-wraper left p-b40">
                          <div className="icon-xs">
                            <i className="fa fa-phone" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">
                              Phone number
                            </h5>
                            <p>(+91) 80 7129 0107</p>
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper left p-b40">
                          <div className="icon-xs">
                            <i className="fa fa-envelope" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">
                              Email address
                            </h5>
                            <p> jcplho@jampanacpl.com </p>
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper left">
                          <div className="icon-xs">
                            <i className="fa fa-map-marker" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">
                              Address info
                            </h5>
                            <p>Jampana Construction Private Limited, No. 1921/970/3, 2nd Floor, HIG, 3rd Phase, A-Sector, Yelahanka, Bangalore, Karnataka – 560064</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gmap-outline">
                <div style={{ height: "400px", width: "100%" }}>
                  {/* <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0" }}
                                        defaultCenter={defaultProps.center}
                                        defaultZoom={defaultProps.zoom}
                                    >
                                        <AnyReactComponent
                                        lat={34.073280}
                                        lng={-118.251410}
                                        text={<i className="fa fa-map-marker" />}
                                        />
                                    </GoogleMapReact> */}
                  <iframe
                    title="Jampana Location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.755927217417!2d77.5746521!3d13.1146426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae18906ad54583%3A0x7d72108631018a8!2sJampana%20Constructions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1738296982014!5m2!1sen!2sin"
                    width="100%"
                    height="400px"
                    style={{ border: 0, borderRadius: "8px" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ContactUs;

import React from 'react';
import { NavLink } from 'react-router-dom';

const filters = [
    { label: "Residential Projects", filter: ".residential" },
    { label: "Hospital & Healthcare", filter: ".healthcare" },
    { label: "Education", filter: ".education" },
    { label: "Government & Defense", filter: ".defense" },
    { label: "Industry system", filter: ".industrial" },
    { label: "Rural  Infrastructure", filter: ".rural" }
];

const projects = [
    // {
    //     image: ('/assets/media/images/projects/project-default.jpg'),
    //     title: 'LIG & MIG Houses',
    //     address: 'Suryanagar Phase – II, Bangalore,',
    //     width: 'col-md-3',
    //     filter: 'residential'
    // },
    {
        image: ('/assets/media/images/projects/residential-composite.jpg'),
        title: 'Composite Housing Scheme',
        address: ' Suryanagar Phase-III, Bangalore',
        width: 'col-md-3',
        filter: 'residential',
        link: '/project-detail/1',
    },
    {
        image: ('/assets/media/images/projects/residential-indira-img.jpg'),
        title: 'Indira Developers, Apartments',
        address: 'Bangalore',
        width: 'col-md-3',
        filter: 'residential',
        link: '/project-detail/2',
    },
    {
        image: ('/assets/media/images/projects/residential-khb.jpg'),
        title: 'Residential Apartment KHB-Yelahana',
        address: 'Yelahanka, Bangalore, Karnataka',
        width: 'col-md-3',
        filter: 'residential',
        link: '/project-detail/3',
    },
    {
        image: ('/assets/media/images/projects/residential-canopy.jpg'),
        title: 'Canopy Dwelling pvt Ltd Apartment',
        address: 'Bangalore',
        width: 'col-md-3',
        filter: 'residential',
        link: '/project-detail/4',
    },
    {
        image: ('/assets/media/images/projects/residential-kpsh.jpg'),
        title: '(KSPH & IDCL, Residential & Non-Residential Buildings)',
        address: 'Various places in Karnataka.',
        width: 'col-md-3',
        filter: 'residential',
        link: '/project-detail/5',
    },
    {
        image: ('/assets/media/images/projects/hospital-hscl.jpg'),
        title: 'HSCL 100 Bedded ESIC Hospitals',
        address: 'Hubbli, Davangere, and Mysore in Karnataka State.',
        width: 'col-md-3',
        filter: 'healthcare',
        link: '/project-detail/6',
    },
    // {
    //     image: ('/assets/media/images/projects/project-default.jpg'),
    //     title: 'KHSDP -  Community Health Centres',
    //     address: ' Gulbarga, Mysore, Mandya, and Belgaum Districts in Karnataka.',
    //     width: 'col-md-3',
    //     filter: 'healthcare'
    // },
    // {
    //     image: ('/assets/media/images/projects/project-default.jpg'),
    //     title: '7 Permanent Social Welfare Residential School Complexes',
    //     address: 'Jaggaiahpeta, Nuzvid, Nandigama, Gudivada (Krishna District), Nizampatnam, T.Sundur (Guntur District), and Markapur (Prakasam District).',
    //     width: 'col-md-3',
    //     filter: 'education'
    // },
    {
        image: ('/assets/media/images/projects/education-rmsas-img.jpg'),
        title: 'Rashtriya Madhyamika Sikshana Abhiyan Schools',
        address: 'Various places in Karnataka.',
        width: 'col-md-3',
        filter: 'education',
        link: '/project-detail/7',
    },
    {
        image: ('/assets/media/images/projects/education-first-grade.jpg'),
        title: 'Karnataka Housing Board - First Grade Colleges ',
        address: 'Various places in Karnataka.',
        width: 'col-md-3',
        filter: 'education',
        link: '/project-detail/8',
    },
    // {
    //     image: ('/assets/media/images/projects/project-default.jpg'),
    //     title: 'RITES Limited Colleges',
    //     address: 'Various places in Karnataka (Package 1 & Package 2)',
    //     width: 'col-md-3',
    //     filter: 'education'
    // },
    {
        image: ('/assets/media/images/projects/education-pwd-colleges.jpg'),
        title: 'PWD Agricultural College Buildings ',
        address: 'Karekare, Hassan Phase – I.',
        width: 'col-md-3',
        filter: 'education',
        link: '/project-detail/9',
    },
    {
        image: ('/assets/media/images/projects/gov-mes-chennai-img.jpg'),
        title: 'Advanced Training Centre Complex, Hostel-II & Mess Building ',
        address: 'National Academy of Direct Taxes, Nagpur.',
        width: 'col-md-3',
        filter: 'defense',
        link: '/project-detail/10',
    },
    {
        image: ('/assets/media/images/projects/gov-nbcc.jpg'),
        title: 'NBCC Regional Hubs for National Security Guards ',
        address: 'Chennai, Hyderabad.',
        width: 'col-md-3',
        filter: 'defense',
        link: '/project-detail/11',
    },
    {
        image: ('/assets/media/images/projects/gov-mess-building-img.jpg'),
        title: 'Military Engineer Services (MES)- Technical Building & Facilities ',
        address: 'Avadi, Chennai.',
        width: 'col-md-3',
        filter: 'defense',
        link: '/project-detail/12',
    },
    {
        image: ('/assets/media/images/projects/rural-rggvy.jpg'),
        title: 'Rural Electrification works under the RGGVY ',
        address: '12th plan in Siddharthnagar District, Uttar Pradesh.',
        width: 'col-md-3',
        filter: 'rural',
        link: '/project-detail/13',
    },
    {
        image: ('/assets/media/images/projects/rural-electrification.jpg'),
        title: 'Madhya Pradesh Poorv Kshetra Vidyut Vitaran Co. materials, including works of a New 33/11 KV Substation.',
        address: 'Madhya Pradesh.',
        width: 'col-md-3',
        filter: 'rural',
        link: '/project-detail/14',
    },
    {
        image: ('/assets/media/images/projects/project-default.jpg'),
        title: 'Industrial Estate by providing CC Roads, RCC Drains, WS & UGD Lines, Septic Tank ',
        address: ' Nelamangala, Bangalore Rural District.',
        width: 'col-md-3',
        filter: 'industrial',
        link: '/project-detail/15',
    },
]

var img1 = ('/assets/media/images/buildings-housing/project-section-bg.jpg');

class OurProject3 extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('/assets/js/masonary.js');

    };
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-parallax bg-cover mobile-page-padding" data-stellar-background-ratio="0.5" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-center">
                                <div className="mt-separator text-white">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Project</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* PAGINATION START */}
                        <div className="filter-wrap p-b15 center">
                            <ul className="masonry-filter link-style  text-uppercase white">
                                <li className="active"><NavLink to={"#"} data-filter="*" data-hover="All">All</NavLink></li>
                                {filters.map((item, index) => (
                                    <li key={index}><NavLink to={"#"} data-filter={item.filter} data-hover={item.label}>{item.label}</NavLink></li>
                                ))}

                            </ul>
                        </div>
                        {/* PAGINATION END */}
                        {/* GALLERY CONTENT START */}
                        <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                            {projects.map((item, index) => (
                                <div key={index} className={`${item.filter} masonry-item col-md-3 col-sm-6 m-b30`}>
                                    <div className="mt-box   image-hover-block">
                                        <div className="mt-thum-bx">
                                            <img src={item.image} alt=""/>
                                        </div>
                                        <div className="mt-info  p-t20 text-white">
                                            <h4 className="mt-tilte m-b10 m-t0">{item.title}</h4>
                                            <p className="m-b0">{item.address}</p>
                                        </div>
                                        <NavLink to={item.link}></NavLink>
                                    </div>
                                </div>
                            ))}

                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                </div>
            </>
        );
    }
};

export default OurProject3;
import React from 'react';
import Header from '../../Common/default/Header';
import Footer from '../../Common/default/Footer';
import Banner from '../../Elements/default/Banner';
import AboutCompany from '../../Elements/default/AboutCompany';
import OurClients from '../../Elements/default/OurClients';

var bnrimg = ('/assets/media/images/banner/banner.jpg');

class Clientele extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner title="Trusted by clients, driven by excellence!" pagename="Our Clients" bgimage={bnrimg}/>
                    <OurClients />
                    <AboutCompany />
                <Footer />
                    
                </div>

                
               

            </>
        );
    }; 
};

export default Clientele;
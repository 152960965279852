import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Services from "./Pages/Services";

import Error from "./Pages/Error";

import ScrollToTop from "./Common/ScrollToTop";

// Default imports
import Home from "./Pages/default/Home";
import ContactUs from "./Pages/default/Contact";
import AboutJampana from "./Pages/default/AboutJampana";
import HistoryJampana from "./Pages/default/HistoryJampana";
import TeamJampana from "./Pages/default/TeamJampana";
import BusinessJampana from "./Pages/default/BusinessJampana";
import Gallery from "./Pages/default/Gallery";
import Clientele from "./Pages/default/Clientele";
import BridgesAndFlyovers from "./Pages/default/BridgesAndFlyovers";
import RuralElectrification from "./Pages/default/RuralInfrastructure";
import LayoutDevelopment from "./Pages/default/LayoutDevelopment";
import Projects from "./Pages/default/Projects";
import ProjectDetail from "./Pages/default/ProjectDetail";
import PageNotFound from "./Pages/default/PageNotFound";
import WorkCompleted from "./Pages/default/work-completed/WorkCompleted";
import WorkOnHand from "./Pages/default/work-on-hand/WorkOnHand";

class Components extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <ScrollToTop />
        <div className="page-wraper">
          <Routes>
            <Route path="/" element={<Home />} />
    

            <Route path="/about" element={<AboutJampana />} />
            <Route path="/services" element={<Services />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/history" element={<HistoryJampana />} />
            <Route path="/team" element={<TeamJampana />} />
            <Route path="/our-business" element={<BusinessJampana />} />
            <Route path="/clientele" element={<Clientele />} />
            <Route path="/projects" element={<Projects />} />
            <Route
              path="/bridges-and-flyovers"
              element={<BridgesAndFlyovers />}
            />
            <Route
              path="/rural-electrification"
              element={<RuralElectrification />}
            />
            <Route path="/layout-development" element={<LayoutDevelopment />} />

            <Route
              path="/project-detail/:projectId"
              element={<ProjectDetail />}
            />
            <Route
              path="/work-completed/:sectionId"
              element={<WorkCompleted />}
            />
            <Route
              path="/works-on-hand/:sectionId"
              element={<WorkOnHand />}
            />

            <Route path="*" element={<PageNotFound />} />

            <Route element={<Error />} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default Components;

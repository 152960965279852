import React from "react";
import { NavLink } from "react-router-dom";

class Navigation extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("/assets/js/masonary.js");
  }

  render() {
    return (
      <>
        <div
          className={
            this.props.bgcolor !== ""
              ? `header-nav navbar-collapse collapse ${this.props.bgcolor}`
              : "header-nav navbar-collapse collapse"
          }
        >
          <ul className="nav navbar-nav">
            <li>
              <NavLink to={"/"}>Home</NavLink>
            </li>
            <li>
              <NavLink to={""}>
                Who We Are <i className="fa fa-chevron-down dropdown-icon"></i>
              </NavLink>
              <ul className="sub-menu">
                <li>
                  <NavLink to={"/about"}>About Jampana</NavLink>
                </li>
                <li>
                  <NavLink to={"/history"}>History</NavLink>
                </li>
                <li>
                  <NavLink to={"/team"}>Team Jampana</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to={""}>
                works completed
                <i className="fa fa-chevron-down dropdown-icon"></i>
              </NavLink>
              <ul className="sub-menu" id="work-completed">
                <li>
                  <NavLink
                    to={"/work-completed/housing-building"}
                    id="housing-building"
                  >
                    Residential
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/work-completed/industrial-buildings"}
                    id="industrial-buildings"
                    className="disabled-link"
                  >
                    Industrial Buildings
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/work-completed/factory-buildings"}
                    id="factory-buildings"
                  >
                    Factories
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/work-completed/school-buildings"}
                    id="school-buildings"
                  >
                    Education
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/work-completed/shopping-complex"}
                    id="shopping-complex"
                    className="disabled-link"
                  >
                    Shopping Complexes
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/work-completed/hotels"} id="hotels">
                    Hotels & Resorts
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/work-completed/hospitals"}
                    id="hospitals"
                  >
                    Hospitals & Healthcare
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/work-completed/bridges-flyovers"}
                    id="bridges-flyovers"
                  >
                    Bridges, Roads & Flyovers
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/work-completed/rural-electrification"}
                    id="rural-electrification"
                  >
                    Rural Electrification
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/work-completed/layout-development"}
                    id="layout-development"
                  >
                    Layout Development
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"#"}
                    id="defense"
                  >
                    Defense
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to={""}>
                works on hand
                <i className="fa fa-chevron-down dropdown-icon"></i>
              </NavLink>
              <ul className="sub-menu" id="work-on-hand">
                <li>
                  <NavLink
                    to={"/works-on-hand/housing-building"}
                    id="housing-building"
                  >
                    Residential
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/works-on-hand/industrial-buildings"}
                    id="industrial-buildings"
                    className="disabled-link"
                  >
                    Industrial Buildings
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/works-on-hand/factory-buildings"}
                    id="factory-buildings"
                  >
                    Factories
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/works-on-hand/school-buildings"}
                    id="school-buildings"
                  >
                    Education
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/works-on-hand/shopping-complex"}
                    id="shopping-complex"
                    className="disabled-link"
                  >
                    Shopping Complexes
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/works-on-hand/hotels"} id="hotels">
                    Hotels & Resorts
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/works-on-hand/hospitals"}
                    id="hospitals"
                  >
                    Hospitals & Healthcare
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/works-on-hand/bridges-flyovers"}
                    id="bridges-flyovers"
                  >
                    Bridges, Roads & Flyovers
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/works-on-hand/rural-electrification"}
                    id="rural-electrification"
                  >
                    Rural Electrification
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/works-on-hand/layout-development"}
                    id="layout-development"
                  >
                    Layout Development
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"#"}
                    id="defense"
                  >
                    Defense
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to={"/projects"}>projects</NavLink>
            </li>
            <li>
              <NavLink to={"/clientele"}>clientele</NavLink>
            </li>
            <li>
              <NavLink to={"/contact-us"}>Contact us</NavLink>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default Navigation;

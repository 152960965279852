export const workData = {
    "housing-building": {
      heading: "Creating Homes, Building Futures",
      description: "At Jampana Constructions Private Limited, we specialize in constructing modern, sustainable housing solutions that blend comfort with style. Our projects focus on creating vibrant living spaces that cater to the evolving needs of families, ensuring both functionality and aesthetic appeal.",
      image: "/assets/media/images/buildings-housing/residential-indira-img.jpg",
      cat: "housing-building",
    },
    "industrial-buildings": {
      heading: "Engineering Industrial Excellence",
      description: "Jampana Constructions Private Limited is dedicated to engineering industrial buildings that maximize efficiency and withstand the toughest environments. We deliver robust, high-performance structures designed to support complex industrial operations with ease and durability.",
      image: "/assets/media/images/buildings-housing/industry-building.jpg",
      cat: "industrial-building",
    },
    "factory-buildings": {
      heading: "Factories That Power Progress",
      description: "At Jampana Constructions Private Limited, we build state-of-the-art factory facilities that drive innovation and enhance productivity. Our designs are tailored to meet diverse manufacturing requirements, ensuring streamlined operations and long-term growth.",
      image: "/assets/media/images/buildings-housing/factory-building.jpg",
      cat: "factory-building",

    },
    "school-buildings": {
      heading: "Inspiring Learning Spaces",
      description: "Jampana Constructions Private Limited is passionate about constructing dynamic, safe, and inspiring school environments. Our buildings are designed to foster learning, creativity, and growth, providing students and educators with spaces where education thrives.",
      image: "/assets/media/images/buildings-housing/education-pwd-colleges.jpg",
      cat: "school-building",

    },
    "shopping-complex": {
      heading: "Vibrant Spaces for Retail Growth",
      description: "At Jampana Constructions Private Limited, we design vibrant shopping complexes that attract high footfall and enhance customer experiences. Our commercial spaces are crafted to support dynamic retail environments, ensuring seamless business operations and aesthetic appeal.",
      image: "/assets/media/images/buildings-housing/shopping-complex.jpg",
      cat: "shopping-complex",

    },
    "hotels": {
      heading: "Hospitality with Architectural Elegance",
      description: "Jampana Constructions Private Limited excels in crafting luxurious hotel spaces that combine comfort, style, and functionality. We focus on creating unique hospitality environments that offer unforgettable guest experiences with sophisticated designs and premium amenities.",
      image: "/assets/media/images/buildings-housing/residential-canopy.jpg",
      cat: "hotels",
    },
    "hospitals": {
      heading: "Healthcare Facilities Built for Care",
      description: "At Jampana Constructions Private Limited, we develop state-of-the-art hospitals and healthcare facilities focused on patient-centric design. Our projects prioritize safety, advanced infrastructure, and efficient layouts to support medical excellence and compassionate care.",
      image: "/assets/media/images/buildings-housing/hospital-hscl.jpg",
      cat: "hospitals",
    },
    "bridges-flyovers": {
      heading: "Creating Homes, Building Futures",
      description: "At Jampana Constructions Private Limited, we specialize in constructing modern, sustainable housing solutions that blend comfort with style. Our projects focus on creating vibrant living spaces that cater to the evolving needs of families, ensuring both functionality and aesthetic appeal.",
      image: "/assets/media/images/buildings-housing/residential-indira-img.jpg",
      cat: "bridges-flyovers",
    },
    "rural-electrification": {
      heading: "Engineering Industrial Excellence",
      description: "Jampana Constructions Private Limited is dedicated to engineering industrial buildings that maximize efficiency and withstand the toughest environments. We deliver robust, high-performance structures designed to support complex industrial operations with ease and durability.",
      image: "/assets/media/images/buildings-housing/industry-building.jpg",
      cat: "rural-electrification",
    },
    "layout-development": {
      heading: "Factories That Power Progress",
      description: "At Jampana Constructions Private Limited, we build state-of-the-art factory facilities that drive innovation and enhance productivity. Our designs are tailored to meet diverse manufacturing requirements, ensuring streamlined operations and long-term growth.",
      image: "/assets/media/images/buildings-housing/factory-building.jpg",
      cat: "layout-development",

    },
  };


  export const workAboutData = {
    "housing-building": {
      heading: "Creating Homes, Building Futures",
      description: "At Jampana Constructions Private Limited, we specialize in constructing modern, sustainable housing solutions that blend comfort with style. Our projects focus on creating vibrant living spaces that cater to the evolving needs of families, ensuring both functionality and aesthetic appeal.",
      image: "/assets/media/images/buildings-housing/residential-indira-img.jpg",
      cat: "housing-building",
    },
    "industrial-buildings": {
      heading: "Engineering Industrial Excellence",
      description: "Jampana Constructions Private Limited is dedicated to engineering industrial buildings that maximize efficiency and withstand the toughest environments. We deliver robust, high-performance structures designed to support complex industrial operations with ease and durability.",
      image: "/assets/media/images/buildings-housing/industry-building.jpg",
      cat: "industrial-building",
    },
    "factory-buildings": {
      heading: "Factories That Power Progress",
      description: "At Jampana Constructions Private Limited, we build state-of-the-art factory facilities that drive innovation and enhance productivity. Our designs are tailored to meet diverse manufacturing requirements, ensuring streamlined operations and long-term growth.",
      image: "/assets/media/images/buildings-housing/factory-building.jpg",
      cat: "factory-building",

    },
    "school-buildings": {
      heading: "Inspiring Learning Spaces",
      description: "Jampana Constructions Private Limited is passionate about constructing dynamic, safe, and inspiring school environments. Our buildings are designed to foster learning, creativity, and growth, providing students and educators with spaces where education thrives.",
      image: "/assets/media/images/buildings-housing/education-pwd-colleges.jpg",
      cat: "school-building",

    },
    "shopping-complex": {
      heading: "Vibrant Spaces for Retail Growth",
      description: "At Jampana Constructions Private Limited, we design vibrant shopping complexes that attract high footfall and enhance customer experiences. Our commercial spaces are crafted to support dynamic retail environments, ensuring seamless business operations and aesthetic appeal.",
      image: "/assets/media/images/buildings-housing/shopping-complex.jpg",
      cat: "shopping-complex",

    },
    "hotels": {
      heading: "Hospitality with Architectural Elegance",
      description: "Jampana Constructions Private Limited excels in crafting luxurious hotel spaces that combine comfort, style, and functionality. We focus on creating unique hospitality environments that offer unforgettable guest experiences with sophisticated designs and premium amenities.",
      image: "/assets/media/images/buildings-housing/residential-canopy.jpg",
      cat: "hotels",
    },
    "hospitals": {
      heading: "Healthcare Facilities Built for Care",
      description: "At Jampana Constructions Private Limited, we develop state-of-the-art hospitals and healthcare facilities focused on patient-centric design. Our projects prioritize safety, advanced infrastructure, and efficient layouts to support medical excellence and compassionate care.",
      image: "/assets/media/images/buildings-housing/hospital-hscl.jpg",
      cat: "hospitals",
    },
    "bridges-flyovers": {
      heading: "Connecting Communities with Strong Foundations",
      description: "At Jampana Constructions Private Limited, we specialize in building durable bridges and flyovers that enhance connectivity and support seamless transportation. Our projects are engineered for safety, efficiency, and longevity, serving as vital links for growing infrastructure.",
      image: "/assets/media/images/works-on-hand/flyover-img.jpg",
      cat: "bridges-flyovers",
    },
    "rural-electrification": {
      heading: "Empowering Lives Through Rural Electrification",
      description: "Jampana Constructions Private Limited is committed to transforming rural landscapes through efficient electrification projects. We focus on sustainable energy solutions that bring light, progress, and economic growth to remote communities.",
      image: "/assets/media/images/works-on-hand/rural-electrification.jpg",
      cat: "rural-electrification",
    },
    "layout-development": {
      heading: "Shaping Urban Landscapes with Precision",
      description: "At Jampana Constructions Private Limited, we excel in comprehensive layout development projects. Our expertise includes meticulous planning, efficient land use, and sustainable infrastructure solutions that foster organized growth and enhance urban living.",
      image: "/assets/media/images/works-on-hand/layout-development.jpg",
      cat: "layout-development",
    },
  };
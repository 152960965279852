import React from "react";
// import { NavLink } from 'react-router-dom';

const services = [
  {
    count: 1,
    title: "Hindustan Steelworks Construction Limited (HSCL)",
    logo: "/assets/media/images/client-logo/hscl.png", 
    description:
      "HSCL is a public sector company that specializes in the construction and development of steel plants and other infrastructure for the steel industry.",
  },
  {
    count: 2,
    title: "Karnataka Housing Board (KHB)",
    logo: "/assets/media/images/client-logo/khb.png", 
    description:
      "The Karnataka Housing Board (KHB) is responsible for the planning, development, and implementation of affordable housing projects across Karnataka. It aims to provide homes for the economically weaker sections of society.",
  },
  {
    count: 3,
    title: "Health and Family Welfare Department (HFWD), Karnataka",
    logo: "/assets/media/images/client-logo/hfwd.png", 
    description:
      "HFWD focuses on improving healthcare infrastructure, providing medical services, and implementing family welfare programs in Karnataka, aiming to improve the quality of life for citizens.",
  },
  {
    count: 4,
    title: "Directorate of Municipal Administration (DMA), Karnataka",
    logo: "/assets/media/images/client-logo/khb.png",
    description:
      "The DMA oversees urban development and municipal governance in Karnataka. Supports various infrastructure, including water supply, sanitation, urban planning.",
  },
  {
    count: 5,
    title: "Nagarjuna Construction Company Limited (NCCL)",
    logo: "/assets/media/images/client-logo/nccl.png",
    description:
      "NCCL is one of India’s leading construction companies, known for its contributions to infrastructure development, including roads, bridges, and residential projects. They work on large-scale civil and industrial projects.",
  },
  {
    count: 6,
    title: "Bangalore Development Authority (BDA)",
    logo: "/assets/media/images/client-logo/bda.png",
    description:
      "The BDA is responsible for urban planning and development in Bangalore, focusing on the creation of residential layouts, commercial spaces, and infrastructure projects to cater to the city’s growing population.",
  },
  {
    count: 7,
    title: "Karnataka Public Works Department",
    logo: "/assets/media/images/client-logo/pwd.png",
    description:
      "This department is responsible for the construction and maintenance of public infrastructure like roads, bridges, and government buildings across Karnataka, ensuring connectivity and smooth functioning of public services.",
  },
  {
    count: 8,
    title: "Andhra Pradesh Roads and Buildings Department",
    logo: "/assets/media/images/client-logo/ap-building-department.png",
    description:
      "The Andhra Pradesh Roads and Buildings Department is tasked with constructing and maintaining highways, state roads, bridges, and public buildings, contributing to the state’s infrastructure growth.",
  },
  {
    count: 9,
    title:
      "Andhra Pradesh Education Welfare Infrastructure Development Corporation (APEWIDC)",
      logo: "/assets/media/images/client-logo/ap-building-department.png",
    description:
      "APEWIDC focuses on building and improving infrastructure in educational institutions in Andhra Pradesh, ensuring the state’s schools and colleges have the necessary facilities and infrastructure.",
  },
  {
    count: 10,
    title: "Purvanchal Vidyut Vitharan Nigam Limited (PuVVNL)",
    logo: "/assets/media/images/client-logo/purvanchal.png",
    description:
      "PuVVNL is responsible for electricity distribution in the eastern part of Uttar Pradesh, ensuring a reliable supply of power to residential, commercial, and industrial consumers.",
  },
  {
    count: 11,
    title: "Military Engineer Services (MES)",
    logo: "/assets/media/images/client-logo/mes.png",
    description:
      "MES provides engineering services to the Indian Army, including the construction and maintenance of defense infrastructure such as airfields, buildings, and military bases.",
  },
  {
    count: 12,
    title: "Karnataka State Small Industries Development Corporation (KSSIDC)",
    logo: "/assets/media/images/client-logo/kssidc.png",
    description:
      "KSSIDC promotes and supports small-scale industries in Karnataka by providing industrial land, financial assistance, and infrastructure development to foster industrial growth.",
  },
  {
    count: 13,
    title: "Rashtriya Madhyamika Sikshana Abhiyan (RMSA)",
    logo: "/assets/media/images/client-logo/rmsa.png",
    description:
      "RMSA is a national program aimed at improving the quality of secondary education in India",
  },
  {
    count: 14,
    title:
      "KSPH & IDCL",
      logo: "/assets/media/images/client-logo/ksph.png",
    description:
      "Provide Infrastructure for the police in Karnataka, including residential colonies and facilities for law enforcement personnel.",
  },
  {
    count: 15,
    title: "RITES Limited",
    logo: "/assets/media/images/client-logo/rites.png",
    description:
      "RITES is a public sector enterprise under the Indian Ministry of Railways, offering services in transportation, infrastructure, and engineering consultancy.",
  },
  {
    count: 16,
    title: "Defence Research and Development Organisation (DRDO)",
    logo: "/assets/media/images/client-logo/drdo.png",
    description:
      "DRDO is a key agency under the Indian Ministry of Defence, focused on developing indigenous defense technologies, including missiles, aircraft, and advanced military systems.",
  },
];

var img1 = "/assets/media/images/background/bg-5.png";
class OurClients extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("/assets/js/masonary.js");
  }
  render() {
    return (
      <>
        <div
          className="section-full p-t80 p-b50 bg-white mobile-page-padding"
          style={{ backgroundImage: "url(" + img1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-center">
                <div className="mt-separator">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">Our</span>{" "}
                    Clients
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                {services.map((item, index) => (
                  <div key={index} className="col-md-4 col-sm-6">
                    <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-white">
                      <div
                        className="mt-icon-box-sm inline-icon text-primary  m-b20 radius  scale-in-center"
                       
                      >
                        <span className="icon-cell text-secondry">
                        <img
                          src={item.logo}
                          alt={item.title}
                          style={{
                            maxWidth: "80px",
                            height: "auto",
                          }}
                        />
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">
                          {item.title}
                        </h4>
                        <p>{item.description}</p>
                        {/* <NavLink to={"#"} className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurClients;

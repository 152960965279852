import React from "react";
import { useParams } from "react-router-dom";
import Header from "../../Common/default/Header";
import Footer from "../../Common/default/Footer";
// import SimilarProjects from "../../Elements/SimilarProjects";
import Banner from "../../Elements/default/Banner";
import { NavLink } from "react-router-dom";
import { projectData } from "../../Constants/data";


const ProjectDetail = () => {
  const { projectId } = useParams();
  const project = projectData.find((proj) => proj.id === projectId);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <>
      <Header />
      <div className="page-content">
        <Banner
          title={project.title}
          pagename="Project Detail"
          bgimage={'/assets/media/images/banner/banner.jpg'}
        />
        <div className="section-full p-tb80 inner-page-padding">
          <div className="container">
            <div className="project-detail-outer">
              <div className="row">
                {project?.images.map((img, index) => (
                  <div key={index} className="col-md-4 col-sm-4">
                    <div className="project-detail-pic m-b30">
                      <div className="mt-media">
                        <img src={img} alt="project" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="product-block">
                    <h4>Cost</h4>
                    <p>{project.cost}</p>
                    <h4>Client</h4>
                    <p>{project.client}</p>
                    <h4>Project type</h4>
                    <p>{project.type}</p>
                    <h4>Location</h4>
                    <p>{project.location}</p>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="mt-box">
                    <img src={project?.mainImage} alt={project.title} />
                  </div>
                </div>
              </div>
              <div className="project-detail-containt">
                <h3>{project.description}</h3>
                <p>{project.details}</p>

              </div>              
            <NavLink to={"/projects"} className="site-button btn-effect">Go Back</NavLink>
            </div>

          </div>
        </div>
        {/* <SimilarProjects /> */}
      </div>
      <Footer />
    </>
  );
};

export default ProjectDetail;

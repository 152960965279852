import React from 'react';
import Header from '../../Common/default/Header';
import Footer from '../../Common/default/Footer';
import Banner from '../../Elements/default/Banner';
import OurServices from '../../Elements/default/OurServices';
import OurTeam from '../../Elements/default/OurTeam';
import Experience from '../../Elements/default/Experience';
import About from '../../Elements/default/About';

var bnrimg = ('/assets/media/images/banner/banner.jpg');

class AboutJampana extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner title="Creating quality urban lifestyles, building stronger communities." pagename="About Us" bgimage={bnrimg}/>
                    <Experience/>
                    <OurServices/>
                    <About />
                    <OurTeam />

                </div>

                <Footer />

            </>
        );
    };
};

export default AboutJampana;
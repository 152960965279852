import React from "react";
import { NavLink } from "react-router-dom";

var bnr1 = "/assets/media/images/background/banner.jpg";

class Callus extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding p-tb80 overlay-wraper bg-cover bg-center"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="overlay-main bg-primary opacity-07" />
          <div className="container">
            <div className="section-content">
              <div className="call-us-section text-center">
                <h4 className="m-b15">Get in touch</h4>
                <h2 className="call-us-number m-b15 m-b0">
                  (+91) 80 7129 0107
                </h2>
                <h4 className="call-us-address m-t0 m-b20">
                  Jampana Construction Private Limited, No. 1921/970/3, <br /> 2nd
                  Floor, HIG, 3rd Phase, A-Sector, Yelahanka, <br /> Bangalore,
                  Karnataka – 560064
                </h4>
                <NavLink
                  to="/contact-us"
                  className="site-button-secondry btn-effect bg-dark"
                >
                  Contact Us
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Callus;

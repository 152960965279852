import React from "react";
import { NavLink } from "react-router-dom";
import Switcher from "../../Elements/default/Switcher";

var bgimage = ("/assets/media/images/background/bg-site.png");

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.currentstate = { logo: ("/assets/media/images/logo.png") };
  }

  updateFooterLogo = (updatedlogo) => {
    this.currentstate.logo = updatedlogo;
  };

  render() {
    return (
      <>
        <footer className="site-footer footer-large  footer-dark	footer-wide">
          <div
            className="container call-to-action-wrap bg-no-repeat bg-center"
            style={{ backgroundImage: "url(" + bgimage + ")" }}
          >
            <div className="p-a30 bg-primary ">
              <div className="row">
                <div className="col-md-8 col-sm-8">
                  <div className="call-to-action-left text-black">
                    <h4 className="text-uppercase m-b10 m-t0">
                      Subscribe to our newsletter!
                    </h4>
                    <span>
                      Never Miss Anything From Jampana By Signing Up To Our
                      Newsletter.
                    </span>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="call-to-action-right">
                    <div className="widget_newsletter">
                      <div className="newsletter-bx">
                        <form role="search" action="./">
                          <div className="input-group">
                            <input
                              name="news-letter"
                              className="form-control"
                              placeholder="ENTER YOUR EMAIL"
                              type="text"
                            />
                            <span className="input-group-btn">
                              <button type="submit" className="site-button">
                                <i className="fa fa-paper-plane-o" />
                              </button>
                            </span>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER BLOCKES START */}
          <div className="footer-top overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                {/* ABOUT COMPANY */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_about">
                    {/*<h4 class="widget-title">About Company</h4>*/}
                    <div className="logo-footer clearfix p-b15">
                      <NavLink to={"./"}>
                        <img src={this.currentstate.logo} alt="" />
                      </NavLink>
                    </div>
                    <p className="max-w400">
                      Jampana – Committed to building excellence, delivering
                      quality, and shaping a better future with every project we
                      undertake.
                    </p>
                    <ul className="social-icons  mt-social-links">
                      <li>
                        <NavLink to={"#"} className="fa fa-google" />
                      </li>
                      <li>
                        <NavLink to={"#"} className="fa fa-rss" />
                      </li>
                      <li>
                        <NavLink to={"#"} className="fa fa-facebook" />
                      </li>
                      <li>
                        <NavLink to={"#"} className="fa fa-twitter" />
                      </li>
                      <li>
                        <NavLink to={"#"} className="fa fa-linkedin" />
                      </li>
                    </ul>
                  </div>
                </div>
                {/* RESENT POST */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_address_outer">
                    <h4 className="widget-title">Contact Us</h4>
                    <ul className="widget_address">
                      <li>Jampana Construction Private Limited, No. 1921/970/3, 2nd Floor, HIG, 3rd Phase, A-Sector, Yelahanka, Bangalore, Karnataka – 560064</li>
                      <li> jcplho@jampanacpl.com</li>
                      <li>(+91) 80 7129 0107</li>
                    </ul>
                  </div>
                </div>
                {/* USEFUL LINKS */}
                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                  <div className="widget widget_services inline-links">
                    <h4 className="widget-title">Useful links</h4>
                    <ul>
                      <li>
                        <NavLink to={"/about"}>About</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/projects"}>Projects</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/clientele"}>Clientele</NavLink>
                      </li>
                      <li>
                        <NavLink to={"contact-us"}>Contact</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* GOOGLE MAP EMBED */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_map">
                    <h4 className="widget-title">Our Location</h4>
                    <div className="map-container">
                      <iframe
                        title="Jampana Location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.755927217417!2d77.5746521!3d13.1146426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae18906ad54583%3A0x7d72108631018a8!2sJampana%20Constructions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1738296982014!5m2!1sen!2sin"
                        width="100%"
                        height="150"
                        style={{ border: 0, borderRadius: "8px" }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}
          <div className="footer-bottom overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                <div className="mt-footer-bot-center">
                  <span className="copyrights-text">
                    {" "}
                    &copy; {new Date().getFullYear()} All rights reserved by
                    Jampana.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)} />
      </>
    );
  }
}

export default Footer;

import React from 'react';
import Header from '../../Common/default/Header';
import Footer from '../../Common/default/Footer';
import Banner from '../../Elements/default/Banner';
import OurTeam from '../../Elements/default/OurTeam';
import Experience from '../../Elements/default/Experience';
import OurServices from '../../Elements/default/OurServices';
import OurTechnicalStaff from '../../Elements/default/OurTechnicalStaff';


var bnrimg = ('/assets/media/images/banner/banner.jpg');

class TeamJampana extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner title="Creating quality urban lifestyles, building stronger communities." pagename="Team" bgimage={bnrimg}/>
                    <OurTeam />
                    <OurTechnicalStaff/>
                    <Experience />
                    <OurServices />

                </div>

                <Footer />

            </>
        );
    };
};

export default TeamJampana;
// Project data ---------------- WORK COMPLETED ---------------------------------------------------------------------------------

export const projects = [
    // {
    //     image: ('/assets/media/images/projects/project-default.jpg'),
    //     title: 'LIG & MIG Houses',
    //     address: 'Suryanagar Phase – II, Bangalore,',
    //     width: 'col-md-3',
    //     filter: 'residential'
    // },
    {
        thumb: ('/assets/media/images/projects/residential-composite.jpg'),
        image: ('/assets/media/images/projects/residential-composite.jpg'),
        title: 'Composite Housing Scheme',
        address: ' Suryanagar Phase-III, Bangalore',
        width: 'col-md-3',
        filter: 'residential',
        projectId: '1'
    },
    {
        thumb: ('/assets/media/images/projects/residential-indira-img.jpg'),
        image: ('/assets/media/images/projects/residential-indira-img.jpg'),
        title: 'Indira Developers, Apartments',
        address: 'Bangalore',
        width: 'col-md-3',
        filter: 'residential',
        projectId: '2'
    },
    {
        thumb: ('/assets/media/images/projects/residential-khb.jpg'),
        image: ('/assets/media/images/projects/residential-khb.jpg'),
        title: 'Residential Apartment KHB-Yelahana',
        address: 'Yelahanka, Bangalore, Karnataka',
        width: 'col-md-3',
        filter: 'residential',
        projectId: '3'
    },
    {
        thumb: ('/assets/media/images/projects/residential-canopy.jpg'),
        image: ('/assets/media/images/projects/residential-canopy.jpg'),
        title: 'Canopy Dwelling pvt Ltd Apartment',
        address: 'Bangalore',
        width: 'col-md-3',
        filter: 'residential',
        projectId: '4'
    },
    {
        thumb: ('/assets/media/images/projects/residential-kpsh.jpg'),
        image: ('/assets/media/images/projects/residential-kpsh.jpg'),
        title: 'KSPH & IDCL, Residential & Non-Residential Buildings',
        address: 'Various places in Karnataka.',
        width: 'col-md-3',
        filter: 'residential',
        projectId: '5'
    },
    {
        thumb: ('/assets/media/images/projects/hospital-hscl.jpg'),
        image: ('/assets/media/images/projects/hospital-hscl.jpg'),
        title: 'HSCL 100 Bedded ESIC Hospitals',
        address: 'Hubbli, Davangere, and Mysore in Karnataka State.',
        width: 'col-md-3',
        filter: 'healthcare',
        projectId: '6'
    },
    // {
    //     image: ('/assets/media/images/projects/project-default.jpg'),
    //     title: 'KHSDP -  Community Health Centres',
    //     address: ' Gulbarga, Mysore, Mandya, and Belgaum Districts in Karnataka.',
    //     width: 'col-md-3',
    //     filter: 'healthcare'
    // },
    // {
    //     image: ('/assets/media/images/projects/project-default.jpg'),
    //     title: '7 Permanent Social Welfare Residential School Complexes',
    //     address: 'Jaggaiahpeta, Nuzvid, Nandigama, Gudivada (Krishna District), Nizampatnam, T.Sundur (Guntur District), and Markapur (Prakasam District).',
    //     width: 'col-md-3',
    //     filter: 'education'
    // },
    {
        thumb: ('/assets/media/images/projects/education-rmsas-img.jpg'),
        image: ('/assets/media/images/projects/education-rmsas-img.jpg'),
        title: 'Rashtriya Madhyamika Sikshana Abhiyan Schools',
        address: 'Various places in Karnataka.',
        width: 'col-md-3',
        filter: 'education',
        projectId: '7'
    },
    {
        thumb: ('/assets/media/images/projects/education-first-grade.jpg'),
        image: ('/assets/media/images/projects/education-first-grade.jpg'),
        title: 'Karnataka Housing Board - First Grade Colleges ',
        address: 'Various places in Karnataka.',
        width: 'col-md-3',
        filter: 'education',
        projectId: '8'
    },
    // {
    //     image: ('/assets/media/images/projects/project-default.jpg'),
    //     title: 'RITES Limited Colleges',
    //     address: 'Various places in Karnataka (Package 1 & Package 2)',
    //     width: 'col-md-3',
    //     filter: 'education'
    // },
    {
        thumb: ('/assets/media/images/projects/education-pwd-colleges.jpg'),
        image: ('/assets/media/images/projects/education-pwd-colleges.jpg'),
        title: 'PWD Agricultural College Buildings',
        address: 'Karekare, Hassan Phase – I.',
        width: 'col-md-3',
        filter: 'education',
        projectId: '9'
    },
    {
        thumb: ('/assets/media/images/projects/gov-mes-chennai-img.jpg'),
        image: ('/assets/media/images/projects/gov-mes-chennai-img.jpg'),
        title: 'Advanced Training Centre Complex, Hostel-II & Mess Building',
        address: 'National Academy of Direct Taxes, Nagpur.',
        width: 'col-md-3',
        filter: 'defense',
        projectId: '10'
    },
    {
        thumb: ('/assets/media/images/projects/gov-nbcc.jpg'),
        image: ('/assets/media/images/projects/gov-nbcc.jpg'),
        title: 'NBCC Regional Hubs for National Security Guards, Chennai ',
        address: 'Chennai, Hyderabad.',
        width: 'col-md-3',
        filter: 'defense',
        projectId: '11'
    },
    {
        thumb: ('/assets/media/images/projects/gov-mess-building-img.jpg'),
        image: ('/assets/media/images/projects/gov-mess-building-img.jpg'),
        title: 'Technical Building & Facilities for Storage Building and RG Shop',
        address: 'HVF-Avadi, Chennai',
        width: 'col-md-3',
        filter: 'defense',
        projectId: '12'
    },
    {
        thumb: ('/assets/media/images/projects/rural-rggvy.jpg'),
        image: ('/assets/media/images/projects/rural-rggvy.jpg'),
        title: 'Rural Electrification works under the RGGVY ',
        address: '12th plan in Siddharthnagar District, Uttar Pradesh.',
        width: 'col-md-3',
        filter: 'rural',
        projectId: '13'
    },
    {
        thumb: ('/assets/media/images/projects/rural-electrification.jpg'),
        image: ('/assets/media/images/projects/rural-electrification.jpg'),
        title: 'Madhya Pradesh Poorv Kshetra Vidyut Vitaran Co. materials, including works of a New 33/11 KV Substation.',
        address: 'Madhya Pradesh.',
        width: 'col-md-3',
        filter: 'rural',
        projectId: '14'
    },
    {
        thumb: ('/assets/media/images/projects/project-default.jpg'),
        image: ('/assets/media/images/projects/project-default.jpg'),
        title: 'Industrial Estate by providing CC Roads, RCC Drains, WS & UGD Lines, Septic Tank ',
        address: ' Nelamangala, Bangalore Rural District.',
        width: 'col-md-3',
        filter: 'industrial',
        projectId: '15'
    },
    {
        thumb: ('/assets/media/images/projects/sky-high-residential.jpg'),
        image: ('/assets/media/images/projects/sky-high-residential.jpg'),
        title: 'Premium High Rise Residential Project at Hyderabad named as "Sky High"  ',
        address: ' Nelamangala, Bangalore Rural District.',
        width: 'col-md-3',
        filter: 'residential',
        projectId: '16'
    },
    {
        thumb: ('/assets/media/images/projects/residential-pc-quaters-dar.jpg'),
        image: ('/assets/media/images/projects/residential-pc-quaters-dar.jpg'),
        title: 'Construction of 96 Nos (48 PC X 2 Blocks) PC Quarters"  ',
        address: 'DAR Davanagere',
        width: 'col-md-3',
        filter: 'residential',
        projectId: '17'
    },
    {
        thumb: ('/assets/media/images/projects/degree-college-admin.jpg'),
        image: ('/assets/media/images/projects/degree-college-admin.jpg'),
        title: 'Nagarjuna Degree College and Admin Block',
        address: ' Bangalore',
        width: 'col-md-3',
        filter: 'education',
        projectId: '18'
    },
    {
        thumb: ('/assets/media/images/projects/trivik-hotel.jpg'),
        image: ('/assets/media/images/projects/trivik-hotel.jpg'),
        title: 'Admin Building, Convention Hall, Swimming Pool, Luxury Suites',
        address: 'Channagondanahalli, Elunoorkhan Rd, Vasthur Hobli, Mullayanagiri',
        width: 'col-md-3',
        filter: 'residential',
        projectId: '19'
    },
    {
        thumb: ('/assets/media/images/projects/high-rise.jpg'),
        image: ('/assets/media/images/projects/high-rise.jpg'),
        title: 'High-Rise Apartments for KHB',
        address: 'Kengeri, Bangalore',
        width: 'col-md-3',
        filter: 'residential',
        projectId: '20'
    },
    {
        thumb: ('/assets/media/images/projects/outer-ring-road.jpg'),
        image: ('/assets/media/images/projects/outer-ring-road.jpg'),
        title: 'Outer Ring Road (ORR), Under Pass 10 Lane with approach ',
        address: 'Mysore Road to Magadi Road',
        width: 'col-md-3',
        filter: 'rural',
        projectId: '21'
    },
    {
        thumb: ('/assets/media/images/projects/rural-composite-scheme-suryanagar.jpg'),
        image: ('/assets/media/images/projects/rural-composite-scheme-suryanagar.jpg'),
        title: 'Development of Composite Housing Scheme at Suryanagar Phase-III, Jigala ',
        address: 'Mysore Road to Magadi Road',
        width: 'col-md-3',
        filter: 'rural',
        projectId: '22'
    },
    {
        thumb: ('/assets/media/images/projects/gov-nbcc-hyd.jpg'),
        image: ('/assets/media/images/projects/gov-nbcc-hyd.jpg'),
        title: 'NBCC Regional Hubs for National Security Guards, Hyderabad',
        address: 'Hyderabad',
        width: 'col-md-3',
        filter: 'defense',
        projectId: '23'
    },
    {
        thumb: ('/assets/media/images/projects/education-nbcc-hostel-mes.jpg'),
        image: ('/assets/media/images/projects/education-nbcc-hostel-mes.jpg'),
        title: ' Advanced Training Centre Complex, Hostel-II & Mess Building',
        address: 'National Academy of Direct Taxes, Nagpur',
        width: 'col-md-3',
        filter: 'education',
        projectId: '24'
    },
    {
        thumb: ('/assets/media/images/projects/hospital-gulbarga-super-spcl.jpg'),
        image: ('/assets/media/images/projects/hospital-gulbarga-super-spcl.jpg'),
        title: 'Super Speciality Hospital, Gulbarga Institute of Medical Sciences',
        address: 'Gulbarga Institute of Medical Sciences, Kalaburagi',
        width: 'col-md-3',
        filter: 'hospital',
        projectId: '25'
    },
    {
      thumb: ('/assets/media/images/projects/foxconn-elephant-project.jpg'),
      image: ('/assets/media/images/projects/foxconn-elephant-project.jpg'),
      title: 'Foxconn Elephant Project, Permanent Road, Drainage and Sewage',
      address: 'Area A at Devanahalli, Bangalore (at Apple Factory)',
      width: 'col-md-3',
      filter: 'rural',
      projectId: '26'
  },
  {
    thumb: ('/assets/media/images/projects/residential-dwelling-unit.jpg'),
    image: ('/assets/media/images/projects/residential-dwelling-unit.jpg'),
    title: 'Foxconn Elephant Project, Permanent Road, Drainage and Sewage',
    address: 'Mysore',
    width: 'col-md-3',
    filter: 'residential',
    projectId: '27'
},
{
  thumb: ('/assets/media/images/projects/pc-quaters-yelahanka.jpg'),
  image: ('/assets/media/images/projects/pc-quaters-yelahanka.jpg'),
  title: 'PC Quatres at Yelahanka, Bangalore',
  address: 'Bangalore Urban District under Police Gruha 2025 (Phase-4), Yelahanka',
  width: 'col-md-3',
  filter: 'residential',
  projectId: '28'
},
{
  thumb: ('/assets/media/images/projects/hospital-sirsi.jpg'),
  image: ('/assets/media/images/projects/hospital-sirsi.jpg'),
  title: '250 bedded Pandit General Hospital building at Sirsi',
  address: 'Sirsi, Uttara Kannada District',
  width: 'col-md-3',
  filter: 'healthcare',
  projectId: '29'
},
{
  thumb: ('/assets/media/images/projects/hospital-Udupi.jpg'),
  image: ('/assets/media/images/projects/hospital-Udupi.jpg'),
  title: '250 Bedded District Hospital building including Staff Quarters and other infrastructure works',
  address: 'Udupi, Udupi District',
  width: 'col-md-3',
  filter: 'healthcare',
  projectId: '30'
},
]

// Project Details data ----------------------------  WORK COMPLETED  --------------------------------------------------------------------

export const projectData = [
    {
      id: "1",
      title: "Composite Housing Scheme",
      location: "Suryanagar Phase-III, Bangalore",
      cost: "53.81 Cr",
      client: "Studio Massimo, Italy",
      type: "Construction, Branding",
      year: "2022",
      description:
        "Creating a sustainable future through building preservation, green architecture, and smart design.",
      details:
        "  Lumpsum fixed price no variation contract for Construction and Commissioning of all the works with two years defect labiality and two years maintenance period which will run concurrently from the last date of the completion of the project for the Proposed Development of Composite Housing Scheme at Suryanagar Phase-III Layout of Block-III at Jigala, Anekal Taluk in Bangalore Urban District",
      images: [
        "/assets/media/images/projects/project-detail/composite-housing/image-1.jpg",
        "/assets/media/images/projects/project-detail/composite-housing/image-2.jpg",
        "/assets/media/images/projects/project-detail/composite-housing/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/composite-housing/image-main.jpg",
    },
    {
      id: "2",
      title: "Indira Developers, Apartments",
      location: "Bangalore",
      cost: " 17.05 Crore",
      client: "Indira Developers",
      type: "Residential",
      year: "2023",
      description:
        "Innovative solutions for residential infrastructure with sustainable designs.",
      details:
        "Our project focuses on creating pleasing apartment spaces that cater to modern living...",
      images: [
        "/assets/media/images/projects/project-detail/indira-apartments/indira-1.jpg",
        "/assets/media/images/projects/project-detail/indira-apartments/indira-2.jpg",
        "/assets/media/images/projects/project-detail/indira-apartments/indira-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/indira-apartments/indira_main.jpg",
    },
    {
      id: "3",
      title: "Residential Apartment KHB-Yelahana",
      location: "Yelahanka, Bangalore, Karnataka",
      cost: "31.38 Crore ",
      client: "KHB Developers",
      type: "Residential",
      year: "2022",
      description:
        "Modern apartments designed with a focus on space, comfort, and sustainability.",
      details:
        "Construction of Residential Apartments for KHB at Yelahanka, Bangalore, Karnataka having 140 Units (B + G + 7 floors)",
      images: [
        "/assets/media/images/projects/project-detail/khb-developers/image-1.jpg",
        "/assets/media/images/projects/project-detail/khb-developers/image-2.jpg",
        "/assets/media/images/projects/project-detail/khb-developers/image-1.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/khb-developers/image-main.jpg",
    },
    {
      id: "4",
      title: "Canopy Dwelling Pvt Ltd Apartment",
      location: "Bangalore",
      cost: " 26.72 Crore",
      client: "Canopy Dwelling Pvt Ltd",
      type: "Residential",
      year: "2023",
      description:
        "Creating sustainable and luxurious living spaces with a focus on modern architecture.",
      details:
        "Construction of Multi-Storied Residential Apartments at Bangalore.",
      images: [
        "/assets/media/images/projects/project-detail/canopy-dwelling/image-1.jpg",
        "/assets/media/images/projects/project-detail/canopy-dwelling/image-2.jpg",
        "/assets/media/images/projects/project-detail/canopy-dwelling/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/canopy-dwelling/image-main.jpg",
    },
    {
      id: "5",
      title: "KSPH & IDCL, Residential & Non-Residential Buildings",
      location: "Various places in Karnataka",
      cost: "95.43 Crores",
      client: "KSPH & IDCL",
      type: "Residential, Non-Residential",
      year: "2023",
      description:
        "A blend of residential and non-residential construction focusing on functionality and comfort.",
      details:
        "Construction Residential and Non-residential buildings at various places in Karnataka",
      images: [
        "/assets/media/images/projects/project-detail/ksph-idcl/image-1.jpg",
        "/assets/media/images/projects/project-detail/ksph-idcl/image-2.jpg",
        "/assets/media/images/projects/project-detail/ksph-idcl/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/ksph-idcl/image-main.jpg",
    },
    {
      id: "6",
      title: "HSCL 100 Bedded ESIC Hospitals",
      location: "Hubbli, Davangere, and Mysore in Karnataka State",
      cost: "84.86 Crore",
      client: "HSCL",
      type: "Healthcare",
      year: "2023",
      description:
        "Building modern healthcare facilities with advanced infrastructure and sustainable practices.",
      details:
        " Proposed 100 Bedded ESIC Hospital at Hubbli, Davangere and Mysore in Karnataka State for Hindustan Steelworks Construction Limited.",
      images: [
        "/assets/media/images/projects/project-detail/hscl/image-1.jpg",
        "/assets/media/images/projects/project-detail/hscl/image-2.jpg",
        "/assets/media/images/projects/project-detail/hscl/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/hscl/image-main.jpg",

    },
    {
      id: "7",
      title: "Rashtriya Madhyamika Sikshana Abhiyan Schools",
      location: "Various places in Karnataka",
      cost: "166.19 Crore",
      client: "Government of Karnataka",
      type: "Education",
      year: "2023",
      description:
        "Educational infrastructure aimed at improving access to quality education in rural areas.",
      details:
        "This project focuses on building schools that provide a conducive learning environment for students in Karnataka.",
      images: [
        "/assets/media/images/projects/project-detail/rmsas-school/rmsas-1.jpg",
        "/assets/media/images/projects/project-detail/rmsas-school/image-2.jpg",
        "/assets/media/images/projects/project-detail/rmsas-school/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/rmsas-school/image-main.jpg",
    },
    {
      id: "8",
      title: "Karnataka Housing Board - First Grade Colleges",
      location: "Various places in Karnataka",
      cost: "32.36 Crore",
      client: "Karnataka Housing Board",
      type: "Education",
      year: "2023",
      description:
        "Designing state-of-the-art college facilities with modern amenities.",
      details:
        "Our project aims to create spacious and resourceful environments for higher education across Karnataka.",
      images: [
        "/assets/media/images/projects/project-detail/ka-firstgrade/image-1.jpg",
        "/assets/media/images/projects/project-detail/ka-firstgrade/image-2.jpg",
        "/assets/media/images/projects/project-detail/ka-firstgrade/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/ka-firstgrade/image-main.jpg",

    },
    {
      id: "9",
      title: "PWD Agricultural College Buildings",
      location: "Karekare, Hassan Phase – I",
      cost: " 31.87 Crore",
      client: "PWD",
      type: "Education",
      year: "2023",
      description:
        "Construction of Agricultural College Buildings at Karekare, Hassan Phase – I.",
      details:
        "The agricultural college includes lecture halls, labs, and spaces for research and hands-on training.",
      images: [
        "/assets/media/images/projects/project-detail/pwd-college/pwd-1.jpg",
        "/assets/media/images/projects/project-detail/pwd-college/pwd-2.jpg",
        "/assets/media/images/projects/project-detail/pwd-college/pwd-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/pwd-college/pwd-main.jpg",
    },
    {
      id: "10",
      title: "Advanced Training Centre Complex, Hostel-II & Mess Building",
      location: "National Academy of Direct Taxes, Nagpur",
      cost: "62.18 Crore.",
      client: "National Academy of Direct Taxes",
      type: "Institutional",
      year: "2023",
      description:
        "Designing modern training centers with all amenities for the growth of professionals.",
      details:
        "Construction of Advanced Training Centre Complex, Hostel-II & Mess Building at National Academy of Direct Taxes, Nagpur for NBCC India Limited ( National Building Construction Corporation Limited (NBCC).",
      images: [
        "/assets/media/images/projects/project-detail/advanced-mes/advanced-1.jpg",
        "/assets/media/images/projects/project-detail/advanced-mes/advanced-2.jpg",
        "/assets/media/images/projects/project-detail/advanced-mes/advanced-1.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/advanced-mes/advanced-main.jpg",
    },
    {
      id: "11",
      title: "NBCC Regional Hubs for National Security Guards, Chennai",
      location: "Chennai",
      cost: "40.25 Crore.",
      client: "NBCC",
      type: "Government",
      year: "2023",
      description:
        "Building regional hubs to provide essential services to the National Security Guards.",
      details:
        "Construction of Regional Hubs for National Security Guards at Chennai for NBCC India Limited.",
      images: [
        "/assets/media/images/projects/project-detail/nbcc-hub/image-1.jpg",
        "/assets/media/images/projects/project-detail/nbcc-hub/image-2.jpg",
        "/assets/media/images/projects/project-detail/nbcc-hub/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/nbcc-hub/nbcc-main.jpg",
    },
    {
      id: "12",
      title: "Technical Building & Facilities for Storage Building and RG Shop",
      location: "HVF-Avadi, Chennai",
      cost: "30.98 Crore",
      client: "Military Engineer Services (MES)",
      type: "Defense",
      year: "2024",
      description:
        "Building technical facilities for the military to ensure operational readiness.",
      details:
        "Construction of Technical Building & Facilities for Storage Building and RG Shop at HVF-Avadi, Chennai.",
      images: [
        "/assets/media/images/projects/project-detail/military-mes/military-1.jpg",
        "/assets/media/images/projects/project-detail/military-mes/military-2.jpg",
        "/assets/media/images/projects/project-detail/military-mes/military-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/military-mes/military-main.jpg",
    },
    {
      id: "13",
      title: "Rural Electrification works under the RGGVY",
      location: "Siddharthnagar District, Uttar Pradesh",
      cost: "119.47 Crore",
      client: "Government of Uttar Pradesh",
      type: "Infrastructure",
      year: "2024",
      description:
        "Providing electricity to rural areas to improve infrastructure and quality of life.",
      details:
        " Rural Electrification works to be carried out under Rajiv Gandhi Grameen Vidyutikaran Yojna under RGGVY 12th plan in Siddharthnagar District of Uttar Pradesh.",
      images: [
        "/assets/media/images/projects/project-detail/rggvy/image-1.jpg",
        "/assets/media/images/projects/project-detail/rggvy/image-2.jpg",
        "/assets/media/images/projects/project-detail/rggvy/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/rggvy/image-main.jpg",
    },
    {
      id: "14",
      title: "Madhya Pradesh Poorv Kshetra Vidyut Vitaran Co. materials, including works of a New 33/11 KV Substation",
      location: "Madhya Pradesh",
      cost: "70.42 Crore",
      client: "Madhya Pradesh Poorv Kshetra Vidyut Vitaran Co.",
      type: "Infrastructure",
      year: "2024",
      description:
        "Building power substations to strengthen electricity distribution systems.",
      details:
        "Supply of materials, Survey, installation, testing and commissioning of Rural Electrification works including works of New 33/11 KV Substation.",
      images: [
        "/assets/media/images/projects/project-detail/poorv-kshetra/image-1.jpg",
        "/assets/media/images/projects/project-detail/poorv-kshetra/image-2.jpg",
        "/assets/media/images/projects/project-detail/poorv-kshetra/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/poorv-kshetra/image-main.jpg",
    },
    {
      id: "15",
      title: "Industrial Estate by providing CC Roads, RCC Drains, WS & UGD Lines, Septic Tank",
      location: "Nelamangala, Bangalore Rural District",
      cost: "X-X-X-X",
      client: "Industrial Development Corporation",
      type: "Industrial",
      year: "2024",
      description:
        "Developing an industrial estate with essential infrastructure for growth and sustainability.",
      details:
        "This project focuses on providing roads, drains, water supply, and sewage systems in an industrial area in Nelamangala.",
      images: [
        "/assets/media/images/projects/project-detail/industrial-estate/image-1.jpg",
        "/assets/media/images/projects/project-detail/industrial-estate/image-2.jpg",
        "/assets/media/images/projects/project-detail/industrial-estate/image-1.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/industrial-estate/image-main.jpg",
    },
    {
      id: "16",
      title: "Construction of Premium High Rise Residential Project at Hyderabad named as Sky High",
      location: "Nelamangala, Bangalore Rural District",
      cost: "370 Crore",
      client: "Harsha Developers, Hyderabad",
      type: "Residential",
      year: "2024",
      description:
        "Construction of a premium high-rise residential project",
      details:
        "This project, undertaken for Harsha Developers in Hyderabad, focuses on developing a world-class residential high-rise with modern architecture, premium facilities, and sustainable infrastructure to enhance urban living",
      images: [
        "/assets/media/images/projects/project-detail/sky-high/image-1.jpg",
        "/assets/media/images/projects/project-detail/sky-high/image-2.jpg",
        "/assets/media/images/projects/project-detail/sky-high/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/sky-high/sky-high-main.jpg",
    },
    {
      id: "17",
      title: "96 Nos (48 PC X 2 Blocks) PC Quarters",
      location: "DAR Davanagere",
      cost: "26.62 Crore",
      client: "Karnataka State Police Housing & Infrastructure Development Corporation Limited (KSPH & IDCL),",
      type: "Residential",
      year: "2025 Scheme",
      description:
        "Construction of 96 Nos (48 PC X 2 Blocks) PC Quarters at DAR Davanagere under Police Gruha 2025 Scheme",
      details:
        "PC Quarters at DAR Davanagere under Police Gruha 2025 Scheme (PG 2025/PH-2/Package No. 11/2021-22) on Lump Sum Turnkey Basis.",
      images: [
        "/assets/media/images/projects/project-detail/pc-quaters-dar/image-1.jpg",
        "/assets/media/images/projects/project-detail/pc-quaters-dar/image-2.jpg",
        "/assets/media/images/projects/project-detail/pc-quaters-dar/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/pc-quaters-dar/image-main.jpg",
    },
    {
      id: "18",
      title: "Nagarjuna Degree College and Admin Block",
      location: "Ananthapura St, Ramagondanahalli, Yelahanka New Town, Bengaluru, Karnataka",
      cost: "68.00 Crore",
      client: "Nagarjuna Education Society, Bangalore",
      type: "Education",
      year: "X X X X",
      description:
        "Construction of Nagarjuna Degree College and Admin Block",
      details:
        "Development of Nagarjuna Degree College and its administrative block, creating a modern educational infrastructure to support quality learning and administration.",
      images: [
        "/assets/media/images/projects/project-detail/nagarjuna-college/image-1.jpg",
        "/assets/media/images/projects/project-detail/nagarjuna-college/image-2.jpg",
        "/assets/media/images/projects/project-detail/nagarjuna-college/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/nagarjuna-college/image-main.jpg",
    },
    {
      id: "19",
      title: "Admin Building, Convention Hall, Swimming Pool, Luxury Suites",
      location: "Channagondanahalli, Elunoorkhan Rd, Vasthur Hobli, Mullayanagiri",
      cost: "204.00 Crore",
      client: "Trivik Hotels & Resorts Pvt Ltd",
      type: "Hotel",
      year: "X X X X",
      description:
        " Construction of Admin Building, Convention Hall, Swimming Pool, Luxury Suites",
      details:
        "Construction of a state-of-the-art Admin Building, a spacious Convention Hall, a modern Swimming Pool, and premium Luxury Suites for Trivik Hotels & Resorts Pvt Ltd.",
      images: [
        "/assets/media/images/projects/project-detail/trivik-hotel/image-1.jpg",
        "/assets/media/images/projects/project-detail/trivik-hotel/image-2.jpg",
        "/assets/media/images/projects/project-detail/trivik-hotel/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/trivik-hotel/image-main.jpg",
    },
    {
      id: "20",
      title: "High-Rise Apartments for KHB at Kengeri, Bangalore. ",
      location: "Kengeri, Bangalore",
      cost: "202.00 Crore",
      client: "Karnataka Housing Board & NCC Limited",
      type: "Apartments",
      year: "X X X X",
      description:
        " High-Rise Apartments for KHB",
      details:
        "Construction of High-Rise Apartments for KHB at Kengeri, Bangalore. 808 Units covering 1137000 Sft.  (B1 + B2 + G + 14 Floors).",
      images: [
        "/assets/media/images/projects/project-detail/high-rise/image-1.jpg",
        "/assets/media/images/projects/project-detail/high-rise/image-2.jpg",
        "/assets/media/images/projects/project-detail/high-rise/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/high-rise/image-main.jpg",
    },
    {
      id: "21",
      title: " Outer Ring Road (ORR), Under Pass 40 Mtr X 180 Mtr, 10 Lane with approache",
      location: "Mysore to Magadi Road",
      cost: "73.29 Crore",
      client: " NCC Limited for Bangalore Development Authority  ",
      type: "Apartments",
      year: "X X X X",
      description:
        " Outer Ring Road (ORR), Under Pass 40 Mtr X 180 Mtr, 10 Lane with approache",
      details:
        "Construction of Outer Ring Road (ORR) between Mysore Road to Magadi Road, Under Pass 40 Mtr X 180 Mtr, 10 Lane with approache",
      images: [
        "/assets/media/images/projects/project-detail/outer-ring-road/image-1.jpg",
        "/assets/media/images/projects/project-detail/outer-ring-road/image-2.jpg",
        "/assets/media/images/projects/project-detail/outer-ring-road/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/outer-ring-road/image-main.jpg",
    },
    {
      id: "22",
      title: " Development of Composite Housing Scheme at Suryanagar Phase-III, Jigala",
      location: "Suryanagar Phase-III Layout of Block-III at Jigala, Anekal Taluk in Bangalore Urban District",
      cost: "53.81 Crore",
      client: " Karnataka Housing Board.",
      type: "Layout Development",
      year: "X X X X",
      description:
        "Development of Composite Housing Scheme at Suryanagar Phase-III, Jigala",
      details:
        "Lumpsum fixed price no variation contract for Construction and Commissioning of all the works with two years defect labiality and two years maintenance period which will run concurrently from the last date of the completion of the project for the Proposed Development of Composite Housing Scheme at Suryanagar Phase-III Layout of Block-III at Jigala, Anekal Taluk in Bangalore Urban District",
      images: [
        "/assets/media/images/projects/project-detail/composite-scheme-suryanagar/image-1.jpg",
        "/assets/media/images/projects/project-detail/composite-scheme-suryanagar/image-1.jpg",
        "/assets/media/images/projects/project-detail/composite-scheme-suryanagar/image-1.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/composite-scheme-suryanagar/image-main.jpg",
    },
    {
      id: "23",
      title: "NBCC Regional Hubs for National Security Guards, Hyderabad",
      location: "Hyderabad",
      cost: "23.23 Crore",
      client: " National Building Construction Corporation Limited (NBCC)",
      type: "Government",
      year: "2023",
      description:
        "Building regional hubs to provide essential services to the National Security Guards.",
      details:
        "Construction of Regional Hubs for National Security Guards at Hyderabad for NBCC India Limited.",
      images: [
        "/assets/media/images/projects/project-detail/nbcc-hyd/image-1.jpg",
        "/assets/media/images/projects/project-detail/nbcc-hyd/image-2.jpg",
        "/assets/media/images/projects/project-detail/nbcc-hyd/image-3.jpg",
      ],
      mainImage:"/assets/media/images/projects/project-detail/nbcc-hyd/image-main.jpg",
    },
    {
        id: "24",
        title: "Advanced Training Centre Complex, Hostel-II & Mess Building ",
        location: "Hyderabad",
        cost: "62.18 Crore",
        client: "National Building Construction Corporation Limited (NBCC)",
        type: "Education",
        year: "X X X X",
        description:
          "Advanced Training Centre Complex, Hostel-II & Mess Building ",
        details:
          "Construction of Advanced Training Centre Complex, Hostel-II & Mess Building at National Academy of Direct Taxes, Nagpur",
        images: [
          "/assets/media/images/projects/project-detail/nbcc-hostel-mess/image-1.jpg",
          "/assets/media/images/projects/project-detail/nbcc-hostel-mess/image-2.jpg",
          "/assets/media/images/projects/project-detail/nbcc-hostel-mess/image-3.jpg",
        ],
        mainImage:"/assets/media/images/projects/project-detail/nbcc-hostel-mess/image-main.jpg",
      },
      {
        id: "25",
        title: "Super Speciality Hospital, Gulbarga Institute of Medical Sciences",
        location: "Gulbarga Institute of Medical Sciences, Kalaburagi",
        cost: "123.84 Crore",
        client: " H & FWD, Engineering Wing, Bengaluru",
        type: "Hospital & Healthcare",
        year: "X X X X",
        description:
          "Super Speciality Hospital, Gulbarga Institute of Medical Sciences",
        details:
          "Construction of Super Speciality Hospital in the premises of Gulbarga Institute of Medical Sciences at Kalaburagi",
        images: [
          "/assets/media/images/projects/project-detail/gulbarga-super-scpl-hospital/image-1.jpg",
          "/assets/media/images/projects/project-detail/gulbarga-super-scpl-hospital/image-2.jpg",
          "/assets/media/images/projects/project-detail/gulbarga-super-scpl-hospital/image-3.jpg",
        ],
        mainImage:"/assets/media/images/projects/project-detail/gulbarga-super-scpl-hospital/image-main.jpg",
      },
      {
        id: "26",
        title: " Foxconn Elephant Project, Permanent Road, Drainage and Sewage",
        location: "Gulbarga Institute of Medical Sciences, Kalaburagi",
        cost: "48.00 Crore",
        client: " M/s. CINDA Engineering Private Limited (Taiwan)",
        type: "Rural Infrastructure, Roads",
        year: "X X X X",
        description:
          " Foxconn Elephant Project, Permanent Road, Drainage and Sewage",
        details:
          "Foxconn Elephant Project including Permanent Road, Drainage and Sewage for Area A at Devanahalli, Bangalore (at Apple Factory)",
        images: [
          "/assets/media/images/projects/project-detail/foxconn-elephant-project/image-1.jpg",
          "/assets/media/images/projects/project-detail/foxconn-elephant-project/image-2.jpg",
          "/assets/media/images/projects/project-detail/foxconn-elephant-project/image-3.jpg",
        ],
        mainImage:"/assets/media/images/projects/project-detail/foxconn-elephant-project/image-main.jpg",
      },
      {
        id: "27",
        title: " Residential Dwelling Units adopting Monolithic Shear Wall Technology ",
        location: "Mysore",
        cost: "171.80 Crore",
        client: "Rajiv Gandhi Housing Corporation Limited",
        type: "Residential",
        year: "X X X X",
        description:
          " Residential Dwelling Units adopting Monolithic Shear Wall Technology",
        details:
          " Construction of 1440 (G+9) Residential Dwelling Units adopting Monolithic Shear Wall Technology with Aluminum Shuttering (Single Pour Single Stage) including Infrastructure at Mysore",
        images: [
          "/assets/media/images/projects/project-detail/residential-dwelling-unit/image-1.jpg",
          "/assets/media/images/projects/project-detail/residential-dwelling-unit/image-2.jpg",
          "/assets/media/images/projects/project-detail/residential-dwelling-unit/image-3.jpg",
        ],
        mainImage:"/assets/media/images/projects/project-detail/residential-dwelling-unit/image-main.jpg",
      },
      {
        id: "28",
        title: "PC Quatres at Yelahanka, Bangalore ",
        location: "Bangalore Urban District under Police Gruha 2025 (Phase-4), Yelahanka",
        cost: "20.34 Crore",
        client: "KSPH & IDCL, Bangalore",
        type: "Residential",
        year: "X X X X",
        description:
          "PC Quatres at Yelahanka, Bangalore ",
        details:
          "Construction of 64 Nos. PC Quatres at Yelahanka, Bangalore in Bangalore Urban District under Police Gruha 2025 (Phase-4) Scheme Package No.33 on Lump Sum Turnkey Basis - Conventional Method of Construction (Framed Structure) (Stilt+G+7 Floors).",
        images: [
          "/assets/media/images/projects/project-detail/pc-quaters-yelahanka/image-1.jpg",
          "/assets/media/images/projects/project-detail/pc-quaters-yelahanka/image-2.jpg",
          "/assets/media/images/projects/project-detail/pc-quaters-yelahanka/image-3.jpg",
        ],
        mainImage:"/assets/media/images/projects/project-detail/pc-quaters-yelahanka/image-main.jpg",
      },
      {
        id: "29",
        title: "250 bedded Pandit General Hospital building at Sirsi",
        location: "Sirsi, Uttara Kannada District",
        cost: "93.15 Crore",
        client: "Sri Siddharth Infratech & Services (I) Private Limited for H & FWD Engineering Wing, Karnataka",
        type: "Hospital & Healthcare",
        year: "X X X X",
        description:
          "250 bedded Pandit General Hospital building at Sirsi",
        details:
          " Upgradation & Construction of 250 bedded Pandit General Hospital building at Sirsi in Uttara Kannada District.",
        images: [
          "/assets/media/images/projects/project-detail/hospital-sirsi/image-1.jpg",
          "/assets/media/images/projects/project-detail/hospital-sirsi/image-2.jpg",
          "/assets/media/images/projects/project-detail/hospital-sirsi/image-3.jpg",
        ],
        mainImage:"/assets/media/images/projects/project-detail/hospital-sirsi/image-main.jpg",
      },
      {
        id: "30",
        title: "250 Bedded District Hospital building including Staff Quarters and other infrastructure works",
        location: " Udupi, Udupi District",
        cost: "103.63 Crore",
        client: "Sri Siddharth Infratech & Services (I) Private Limited for H & FWD Engineering Wing, Karnataka",
        type: "Hospital & Healthcare",
        year: "X X X X",
        description:
          "250 Bedded District Hospital building including Staff Quarters and other infrastructure works",
        details:
          "Upgradation & Construction of 250 Bedded District Hospital building including Staff Quarters and other infrastructure works at Udupi in Udupi District.",
        images: [
          "/assets/media/images/projects/project-detail/hospital-udupi/image-1.jpg",
          "/assets/media/images/projects/project-detail/hospital-udupi/image-2.jpg",
          "/assets/media/images/projects/project-detail/hospital-udupi/image-3.jpg",
        ],
        mainImage:"/assets/media/images/projects/project-detail/hospital-udupi/image-main.jpg",
      },
     
     
  ];






  // Project data ---------------- WORK ON HAND ---------------------------------------------------------------------------------


export const projectDataworkOnHand = [
    // Bridges and flyovers
    {
      cat: "bridges-flyovers",
      title: "Foxconn Elephant Project, Permanent Road, Drainage and Sewage",
      image: "/assets/media/images/projects/foxconn-elephant-project.jpg",
    },
    {
      cat: "housing-building",
      title: "Residential Dwelling Units adopting Monolithic Shear Wall Technology with Aluminum Shuttering",
      image: "/assets/media/images/projects/residential-dwelling-unit.jpg",
    },
    {
      cat: "housing-building",
      title: "PC Quatres at Yelahanka, Bangalore ",
      image: "/assets/media/images/projects/pc-quaters-yelahanka.jpg",
    },
    {
      cat: "hospitals",
      title: "250 bedded Pandit General Hospital building at Sirsi",
      image: "/assets/media/images/projects/hospital-sirsi.jpg",
    },
    {
      cat: "hospitals",
      title: "250 Bedded District Hospital building including Staff Quarters and other infrastructure works",
      image: "/assets/media/images/projects/hospital-udupi.jpg",
    },
  ];
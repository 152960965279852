import React from 'react';
import { NavLink } from 'react-router-dom';


var img1 = ('/assets/media/images/value-image.jpg');

class OurValue2 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white mobile-page-padding">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-7 col-sm-6">
                                    <div className="video-section-full-v2">
                                        <div className="video-section-full bg-no-repeat bg-cover overlay-wraper m-b30" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                                            <div className="" />
                                                <div className="video-section-inner">
                                                    <div className="video-section-content">

                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-6">
                                    {/* TITLE START */}
                                    <div className="video-part-2">
                                    <h3 className="m-t0"><span className="font-weight-100"> We have</span><span className="text-primary"> 21+ years</span> experience in construction</h3>
                                        <p>At Jampana, integrity, quality, and reliability are the foundation of everything we build, ensuring trust and excellence in every project we deliver.</p>
                                        <ul className="list-angle-right anchor-line">
                                        <li><NavLink to={"#"}>Construction Management</NavLink></li>
                                            <li><NavLink to={"#"}>Architectural Solutions</NavLink></li>
                                            <li><NavLink to={"#"}>Contruction Administration</NavLink></li>
                                            <li><NavLink to={"#"}>Pre construction Services</NavLink></li>
                                        </ul>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="author-info author-info-v2 p-t20">
                                        {/* <div className="author-signature">
                                            <img src={('/assets/media/images/Signature+Black.png')} alt="Signature" width={150} />
                                        </div> */}
                                        <div className="author-name">
                                            <h4 className="m-t0">J. Srinivasa Raju</h4>
                                            <p>Managing Director</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default OurValue2;
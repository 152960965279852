import React from 'react';
import Header from '../../Common/default/Header';
import Footer from '../../Common/default/Footer';
import Slider from '../../Elements/default/Slider';
import Specialization from '../../Elements/default/Specialization';
import About from '../../Elements/default/About';
import OurValue2 from '../../Elements/default/OurValue2';
import OurMission from '../../Elements/default/OurMission';
import OurServices from '../../Elements/default/OurServices';
import Callus from '../../Elements/default/Callus';
import OurProject from '../../Elements/default/OurProject';
// import Blogs from '../../Elements/Blogs';

class Home extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Slider />
                    <Specialization />
                    <About />
                    <OurValue2 />
                    <OurMission />
                    <OurServices />
                    <Callus />
                    <OurProject />
                </div>
                <Footer />
            </>
        );
    };
};

export default Home;
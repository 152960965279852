import React from "react";

class OurBusiness extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50 bg-white inner-page-padding">
          <div className="container">
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-md-12 col-sm-6">
                  {/* TITLE START */}
                  <div className="section-head">
                    <div className="mt-separator-outer separator-left">
                      <div className="mt-separator">
                        <h2 className="text-uppercase sep-line-one ">
                          <span className="font-weight-300 text-primary">
                            Our
                          </span>{" "}
                          Business
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* TITLE END */}
                  <div className="section-content p-b30">
                    <div className="mt-accordion acc-bg-gray" id="accordion5">
                      <div className="panel mt-panel">
                        <div className="acod-head acc-actives">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseOne5"
                              data-parent="#accordion5"
                            >
                              Quality Assurance
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div
                          id="collapseOne5"
                          className="acod-body collapse in"
                        >
                          <div className="acod-content p-tb15">
                            <p>
                              {" "}
                              We consistently deliver high-quality projects by
                              strictly adhering to set specifications,
                              contractual obligations, and regulatory
                              requirements. Our focus is on maintaining the
                              highest standards at every stage of construction.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel mt-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Customer Satisfaction
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseTwo5" className="acod-body collapse">
                          <div className="acod-content p-tb15">
                            We strive to enhance customer satisfaction through
                            cost-effective practices, timely delivery, and a
                            strong emphasis on safety. Our goal is to build
                            lasting relationships with our clients based on
                            trust and reliability.
                          </div>
                        </div>
                      </div>
                      <div className="panel mt-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseThree5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              International Standards Compliance
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseThree5" className="acod-body collapse">
                          <div className="acod-content p-tb15">
                            We regularly update and implement procedures that
                            align with international best practices, ensuring
                            that our operations are efficient, effective, and
                            globally competitive.
                          </div>
                        </div>
                      </div>
                      <div className="panel mt-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFour5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Cost & Time Efficiency
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseFour5" className="acod-body collapse">
                          <div className="acod-content p-tb15">
                            We are committed to completing projects without cost
                            overruns or delays. Our strategic approach to
                            project management ensures optimal resource
                            utilization and timely execution
                          </div>
                        </div>
                      </div>
                      <div className="panel mt-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Continuous Improvement
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseFive5" className="acod-body collapse">
                          <div className="acod-content p-tb15">
                            Our team is dedicated to continual improvement. We
                            motivate and train our staff to uphold and exceed
                            quality standards, fostering a culture of growth and
                            excellence
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FAQ */}
        </div>
      </>
    );
  }
}

export default OurBusiness;

import React from 'react';
import Header from '../../Common/default/Header';
import Footer from '../../Common/default/Footer';
import Banner from '../../Elements/default/Banner';
import OurValue2 from '../../Elements/default/OurValue2';
import OurBusiness from '../../Elements/default/OurBusiness';


var bnrimg = ('/assets/media/images/banner/banner.jpg');

class BusinessJampana extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner title="Creating quality urban lifestyles, building stronger communities." pagename="Our Business" bgimage={bnrimg}/>
                    <OurValue2 />
                    <OurBusiness/>

                </div>

                <Footer />

            </>
        );
    };
};

export default BusinessJampana;
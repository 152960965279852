import React from "react";
import { workAboutData } from "../../../Constants/workData";


  

class WorkCompletedAbout extends React.Component {
  render() {
    const { sectionId} = this.props;
    const content = workAboutData[sectionId] || {
      heading: "Excellence in Every Structure",
      description: "Delivering unmatched quality in construction projects tailored to your needs.",
      image: "/assets/media/images/default.png",
    };

    return (
      <div className="section-full p-t80 bg-white">
        <div className="container">
          <div className="section-content">
            <div className="m-service-containt text-black">
              <div className="row">
                <div className="col-md-5 col-sm-12">
                  <div className="service-about-left">
                    <div className="mt-media">
                      <img src={content.image} alt={content.heading} />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-sm-12">
                  <div className="service-about-right m-b30">
                    <h3 className="m-t0">{content.heading}</h3>
                    <p>{content.description}</p>
                    {/* <p><strong>Category:</strong> {cat}</p> */}
                    <div className="call-for-quote-outer">
                      <div className="call-quote">
                        <span>Call For a Quote:</span>
                        <h4>(+91) 80 7129 0107</h4>
                      </div>
                      <div className="call-estimate bg-dark">
                        <a href="contact-us" className="site-button-secondry btn-effect">
                          Get in touch
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkCompletedAbout;

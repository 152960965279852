import React from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { projectDataworkOnHand } from '../../../Constants/data';

const bnr1 = '/assets/media/images/background/bg-6.png';

const WorkOnHandProjects = () => {
  const { sectionId } = useParams(); // Get the sectionId from the URL
  const filteredProjects = projectDataworkOnHand.filter(project => project.cat === sectionId); // Filter by category

  return (
    <div className="section-full p-t80 p-b50 bg-dark mobile-page-padding" style={{ backgroundImage: `url(${bnr1})` }}>
      <div className="container">
        <div className="section-content">
          <div className="section-head m-b50 text-white text-center">
            <h3 className="m-t0">Transforming Visions into Reality with Exceptional Projects</h3>
            <p>
              Jampana Constructions Private Limited takes pride in creating remarkable projects. Explore our
              innovative designs, sustainable solutions, and architectural excellence tailored to your needs.
            </p>
          </div>

          <div className="row">
            {filteredProjects.length > 0 ? (
              filteredProjects.map((project, index) => (
                <div className="col-md-4 col-sm-6 m-b30" key={index}>
                  <div className="image-effect-one hover-shadow">
                    <img src={project.image} alt={project.title} />
                    <div className="figcaption">
                      <h4>{project.title}</h4>
                      <p>{project.description}</p>
                      {/* <NavLink to={`/project-detail/${sectionId}`}>
                        <i className="link-plus bg-primary" />
                      </NavLink> */}
                      <NavLink to={`#`}>
                        <i className="link-plus bg-primary" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center text-white">
                <h5>We are currently working on the sector.</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOnHandProjects;

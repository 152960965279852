import React from 'react';
import Header from '../../Common/default/Header';
import Footer from '../../Common/default/Footer';
import Banner from '../../Elements/default/Banner';
import OurValues from '../../Elements/default/OurValues';
import Faq from '../../Elements/default/Faq';
import AboutCompany from '../../Elements/default/AboutCompany';
import AboutSummary from '../../Elements/default/AboutSummary';
import OurServices from '../../Elements/default/OurServices';

var bnrimg = ('/assets/media/images/banner/banner.jpg');

class HistoryJampana extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner title="Diversified services. Unvarying quality." pagename="History" bgimage={bnrimg}/>
                    <AboutCompany />
                    <OurValues />
                    <AboutSummary />
                    <OurServices />
=                    <Faq />
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default HistoryJampana;
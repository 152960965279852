import React from 'react';
import { useParams, NavLink } from 'react-router-dom';

// Dummy data for projects
const projectData = [
  // Houses
  {
    cat: "housing-building",
    title: "Composite Housing Scheme",
    image: "/assets/media/images/projects/residential-composite.jpg",
  },
  {
    cat: "housing-building",
    title: "Indira Developers, Apartments",
    image: "/assets/media/images/projects/residential-indira-img.jpg",
  },
  {
    cat: "housing-building",
    title: "Residential Apartment KHB-Yelahanka",
    image: "/assets/media/images/projects/residential-khb.jpg",
  },
  {
    cat: "housing-building",
    title: "Canopy Dwelling Pvt Ltd Apartment",
    image: "/assets/media/images/projects/residential-canopy.jpg",
  },
  {
    cat: "housing-building",
    title: "KSPH & IDCL Residential & Non-Residential Buildings",
    image: "/assets/media/images/projects/residential-kpsh.jpg",
  },
  {
    cat: "housing-building",
    title: "Construction of 96 Nos (48 PC X 2 Blocks) PC Quarters",
    image: "/assets/media/images/projects/residential-pc-quaters-dar.jpg",
  },
  {
    cat: "housing-building",
    title: "High Rise Residential Project - Sky High",
    image: "/assets/media/images/projects/sky-high-residential.jpg",
  },

  // Industrial Buildings
  {
    cat: "industrial-buildings",
    title: "Industrial Estate Development",
    image: "/assets/media/images/projects/project-default.jpg",
  },

  // Factories
  {
    cat: "factory-buildings",
    title: "Technical Building & Facilities for Storage Building and RG Shop",
    image: "/assets/media/images/projects/gov-mess-building-img.jpg",
  },

  // Schools
  {
    cat: "school-buildings",
    title: "Rashtriya Madhyamika Sikshana Abhiyan Schools",
    image: "/assets/media/images/projects/education-rmsas-img.jpg",
  },
  {
    cat: "school-buildings",
    title: "Karnataka Housing Board - First Grade Colleges",
    image: "/assets/media/images/projects/education-first-grade.jpg",
  },
  {
    cat: "school-buildings",
    title: "PWD Agricultural College Buildings",
    image: "/assets/media/images/projects/education-pwd-colleges.jpg",
  },
  {
    cat: "school-buildings",
    title: "Nagarjuna Degree College",
    image: "/assets/media/images/projects/degree-college-admin.jpg",
  },

  // Hospitals
  {
    cat: "hospitals",
    title: "HSCL 100 Bedded ESIC Hospitals",
    image: "/assets/media/images/projects/hospital-hscl.jpg",
  },
  // Hotels
  {
    cat: "hotels",
    title: "Trivik Hotels & Resorts Pvt Ltd",
    image: "/assets/media/images/projects/trivik-hotel.jpg",
  },
  // Rural Electrification
  {
    cat: "rural-electrification",
    title: "Madhya Pradesh Poorv Kshetra Vidyut Vitaran Co. materials, including works of a New 33/11 KV Substation.",
    image: "/assets/media/images/projects/rural-electrification.jpg",
  },
  {
    cat: "rural-electrification",
    title: "Rural Electrification works under the RGGVY",
    image: "/assets/media/images/projects/rural-rggvy.jpg",
  },
  //  Layout Development
  {
    cat: "layout-development",
    title: "Composite Housing Scheme, Bangalore",
    image: "/assets/media/images/projects/residential-composite.jpg",
  },
  {
    cat: "layout-development",
    title: "Industrial Estate by providing CC Roads, RCC Drains, WS & UGD Lines, Septic Tank ",
    image: "/assets/media/images/projects/project-default.jpg",
  },
  // Bridges and flyovers
  {
    cat: "bridges-flyovers",
    title: "Outer Ring Road (ORR), Under Pass 10 Lane with approach",
    image: "/assets/media/images/projects/outer-ring-road.jpg",
  },
];


const bnr1 = '/assets/media/images/background/bg-6.png';

const WorkCompletedProjects = () => {
  const { sectionId } = useParams(); // Get the sectionId from the URL
  const filteredProjects = projectData.filter(project => project.cat === sectionId); // Filter by category

  return (
    <div className="section-full p-t80 p-b50 bg-dark mobile-page-padding" style={{ backgroundImage: `url(${bnr1})` }}>
      <div className="container">
        <div className="section-content">
          <div className="section-head m-b50 text-white text-center">
            <h3 className="m-t0">Transforming Visions into Reality with Exceptional Projects</h3>
            <p>
              Jampana Constructions Private Limited takes pride in creating remarkable projects. Explore our
              innovative designs, sustainable solutions, and architectural excellence tailored to your needs.
            </p>
          </div>

          <div className="row">
            {filteredProjects.length > 0 ? (
              filteredProjects.map((project, index) => (
                <div className="col-md-4 col-sm-6 m-b30" key={index}>
                  <div className="image-effect-one hover-shadow">
                    <img src={project.image} alt={project.title} />
                    <div className="figcaption">
                      <h4>{project.title}</h4>
                      <p>{project.description}</p>
                      {/* <NavLink to={`/project-detail/${sectionId}`}>
                        <i className="link-plus bg-primary" />
                      </NavLink> */}
                      <NavLink to={`#`}>
                        <i className="link-plus bg-primary" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center text-white">
                <h5>Currently working on the sector.</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkCompletedProjects;

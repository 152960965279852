import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: 1,
        title: 'Residential',
        flaticon: 'flaticon-project',
        link: '/work-completed/housing-building'
    },
    {
        count: 2,
        title: 'Industrial Buildings',
        flaticon: 'flaticon-crane',
        link: '/work-completed/industrial-buildings'
    },
    {
        count: 3,
        title: 'Factories',
        flaticon: 'flaticon-tower',
        link: '/work-completed/factory-buildings'
    },
    {
        count: 4,
        title: 'Education',
        flaticon: 'flaticon-house',
        link: '/work-completed/school-buildings'
    },
    {
        count: 5,
        title: 'Shopping Complexes',
        flaticon: 'flaticon-chart',
        link: '/work-completed/shopping-complex'
    },
    {
        count: 6,
        title: 'Hotels & Resorts',
        flaticon: 'flaticon-window',
        link: '/work-completed/hotels'
    },
    {
        count: 7,
        title: 'Hospitals & Healthcare',
        flaticon: 'flaticon-hotel',
        link: '/work-completed/hospitals'
    },
    {
        count: 8,
        title: 'Bridges, Roads & Flyovers',
        flaticon: 'flaticon-road',
        link: '/work-completed/bridges-flyovers'
    },
    {
        count: 8,
        title: 'Rural Electrification',
        flaticon: 'flaticon-plan',
        link: '/work-completed/rural-electrification'
    },
    {
        count: 9,
        title: 'Layout Development',
        flaticon: 'flaticon-chart',
        link: '/work-completed/layout-development'
    },
    {
        count: 10,
        title: 'Defense',
        flaticon: 'flaticon-hotel',
        link: '#'
    },
]

var img1 = ('/assets/media/images/background/bg-5.png');
var img2 = ('/assets/media/images/background/line.png');

class WorkCompletedCardNav extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('/assets/js/masonary.js');
      
    };
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white mobile-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-center">
                                <div className="mt-separator">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Other</span> Sectors</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                            {services.map((item, index) => (
                                <div key={index} className="col-md-4 col-sm-6">
                                    <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-white">
                                        <div className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-primary  scale-in-center bg-moving" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                            <span className="icon-cell text-secondry"><i className={item.flaticon} /></span>
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">{item.title}</h4>
                                            <NavLink to={item.link} className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            ))}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default WorkCompletedCardNav;
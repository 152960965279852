import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../Common/default/Header';
import Footer from '../../../Common/default/Footer';
import Banner from '../../../Elements/default/Banner';
import OurProject3 from '../../../Elements/default/OurProject3';
import Faq from '../../../Elements/default/Faq';
import OurValues from '../../../Elements/default/OurValues';
import { workData } from '../../../Constants/workData';
import WorkOnHandAbout from '../../../Elements/default/work-on-hand/WorkOnHandAbout';
import WorkOnHandProjects from '../../../Elements/default/work-on-hand/WorkOnHandProjects';
import WorkOnHandCardNav from '../../../Elements/default/work-on-hand/WorkOnHandCardNav';

const bnrimg = '/assets/media/images/banner/banner.jpg';

const WorkOnHand = () => {
    const { sectionId } = useParams();

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner 
                    title="Building the Future with Strong Foundations and Innovative Infrastructure Solutions" 
                    pagename="Infrastructure" 
                    bgimage={bnrimg} 
                />
                <WorkOnHandAbout sectionId={sectionId} cat={workData[sectionId]?.cat} />
                <WorkOnHandProjects sectionId={sectionId} />
                <WorkOnHandCardNav sectionId={sectionId} />
                <OurValues sectionId={sectionId} />
                <OurProject3/>
                <Faq />
                <Footer />
            </div>
        </>
    );
};

export default WorkOnHand;

import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const images = [
  "/assets/media/images/gallery/experience-5.jpg",
  "/assets/media/images/gallery/experience-2.jpg",
  "/assets/media/images/gallery/experience-3.jpg",
  "/assets/media/images/gallery/experience-4.jpg",
  "/assets/media/images/gallery/experience-1.jpg",
];

var bnr1 = "/assets/media/images/background/line.png";

class Experience extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("/assets/js/masonary.js");
  }

  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: true,
    };
    return (
      <>
        <div className="section-full p-t80 p-b80 bg-white inner-page-padding">
          <div className="container">
            <div className="section-content ">
              <div className="row">
                <div className="col-lg-5 col-md-5 ">
                  <div className="m-about ">
                    <OwlCarousel
                      className="owl-carousel about-us-carousel"
                      {...options}
                    >
                      {images.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="ow-img">
                            <NavLink to="/about">
                              <img src={item} alt="" />
                            </NavLink>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7">
                  <div className="m-about-containt text-black p-t80">
                    <div
                      className="m-about-years bg-moving"
                      style={{ backgroundImage: "url(" + bnr1 + ")" }}
                    >
                      <span className="text-primary large-title">21+</span>
                      <span className="large-title-info">
                        Years of Experience
                      </span>
                    </div>
                    <h3 className="font-weight-600">
                      Improving quality of life with an integrated unified
                      approach.
                    </h3>
                    <p>
                      Jampana Constructions Private Limited is a leading
                      construction company dedicated to delivering high-quality
                      infrastructure and building projects. With a strong
                      commitment to excellence, innovation, and sustainability,
                      we specialize in residential, commercial, industrial, and
                      institutional projects. Our expertise spans from designing
                      and constructing modern housing developments to executing
                      large-scale industrial and urban infrastructure projects.
                    </p>
                    <div className="author-info p-t20">
                      <div className="author-name">
                        <h4 className="m-t0">J. Srinivasa Raju</h4>
                        <p>Managing Director</p>
                      </div>
                      <NavLink
                        to="/contact-us"
                        className="site-button btn-effect m-b15"
                      >
                        <span>Get in touch</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Experience;

import React from "react";

var img1 = "/assets/media/images/background/bg-6.png";
var img2 = "/assets/media/images/background/line.png";

class AboutSummary extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("/assets/js/masonary.js");
  }
  render() {
    return (
      <>
        <div
          className="section-full p-t80 p-b80 bg-dark bg-repeat square_shape2 inner-page-padding"
          style={{ backgroundImage: "url(" + img1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-left">
                <div className="mt-separator text-white">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">
                      HISTORY
                    </span>{" "}
                    of Jampana
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content our-story">
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img
                        src={"/assets/media/images/our-history/2003.jpg"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div
                    className="mt-box our-story-detail bg-moving bg-cover"
                    style={{ backgroundImage: "url(" + img2 + ")" }}
                  >
                    <h4 className="text-uppercase m-b20">2003 - Present</h4>
                    <p>
                      Jampana Constructions Private Limited, a leading name in
                      the construction industry, was founded in 2003 with a
                      vision to deliver high-quality construction solutions.
                      Based in the heart of Bangalore, on Ananthapura Road,
                      Yelahanka, the company has established a strong presence
                      in the region. With years of expertise and a commitment to
                      excellence, Jampana Constructions has successfully
                      completed a wide range of residential, commercial, and
                      industrial projects, earning a reputation for reliability,
                      innovation, and superior craftsmanship.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img
                        src={"/assets/media/images/our-history/what-happened.jpg"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div
                    className="mt-box our-story-detail bg-moving bg-cover"
                    style={{ backgroundImage: "url(" + img2 + ")" }}
                  >
                    <h3 className="text-uppercase m-b20">Our Journey of Growth</h3>
                    <p>
                      Over the years, Jampana Constructions Private Limited has
                      achieved significant milestones, expanding its footprint
                      in the construction industry. From our humble beginnings
                      in 2003, we have successfully completed a wide range of
                      projects, including residential, commercial, and
                      industrial developments. Our commitment to quality and
                      innovation has earned us recognition as a trusted name in
                      the construction sector. With a dedicated team and a focus
                      on excellence, we continue to push the boundaries of what
                      is possible in construction, delivering projects that
                      shape the future.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img
                        src={"/assets/media/images/projects/hospital-hscl.jpg"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div
                    className="mt-box our-story-detail bg-moving bg-cover"
                    style={{ backgroundImage: "url(" + img2 + ")" }}
                  >
                    <h3 className="text-uppercase text-white text-left m-b20">Why Us</h3>
                    <h4 className="text-left m-b20">
                      WE ARE TRUSTABLE AND EFFICIENT
                    </h4>
                    <p className="text-left">
                      Strong, experienced and dedicated management team and
                      skilled work force – 200 employees including 100 Engineers
                      Almost two thirds of managers have engineering degrees.{" "}
                      <hr />
                      Project Managers reporting to Director/ Coordinator/
                      Project Head are responsible for the quality and timely
                      completion of their Projects
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img
                        src={"/assets/media/images/our-history/objectives.jpg"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div
                    className="mt-box our-story-detail bg-moving bg-cover"
                    style={{ backgroundImage: "url(" + img2 + ")" }}
                  >
                    <h3 className="text-uppercase m-b20">
                      Our Objectives
                    </h3>
                    <p>
                      Our mission is to consistently deliver quality products by
                      adhering to set specifications, as well as contractual,
                      regulatory, and statutory requirements. We strive to
                      achieve enhanced customer satisfaction through
                      cost-effective and timely project completion. By
                      motivating and training our staff, we aim for continual
                      improvement of quality standards. Additionally, we are
                      committed to updating and implementing procedures that
                      comply with international standards, while ensuring that
                      projects are executed without cost overruns or time
                      delays.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img
                        src={"/assets/media/images/our-history/quality-policy.jpg"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div
                    className="mt-box our-story-detail bg-moving bg-cover"
                    style={{ backgroundImage: "url(" + img2 + ")" }}
                  >
                    <h3 className="text-uppercase text-white m-b20">Quality Policy</h3>
                    <p>
                      Jampana Construction Private Limited strives to achieve
                      enhanced customer satisfaction by delivering quality
                      product through timely completion with safe working
                      environment. We dedicate ourselves for continual
                      improvement in all fields of our business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutSummary;

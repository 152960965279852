import React from "react";

const technicalStaff = [
  { designation: "Chief General Manager – Tech", persons: 1 },
  { designation: "Senior Project Manager", persons: 5 },
  { designation: "Project Manager", persons: 10 },
  { designation: "Deputy Project Manager – Contract", persons: 1 },
  { designation: "Deputy Project Manager", persons: 7 },
  { designation: "Senior Engineer", persons: 10 },
  { designation: "Assistant Engineer", persons: 18 },
  { designation: "Junior Engineer", persons: 35 },
  { designation: "Graduate Trainee Engineer", persons: 25 },
  { designation: "Electrical Engineer", persons: 5 },
  { designation: "Safety Engineer", persons: 5 },
  { designation: "Survey Engineer", persons: 8 },
  { designation: "QC Engineer", persons: 5 },
  { designation: "Technical Assistants", persons: 65 },
];

const OurTechnicalStaff = () => {
  return (
    <div className="section-full p-t80 p-b50 bg-white mobile-page-padding">
      <div className="container">
        {/* TITLE */}
        <div className="section-head">
          <div className="mt-separator-outer separator-center">
            <div className="mt-separator">
              <h2 className="text-uppercase sep-line-one">
                <span className="font-weight-300 text-primary">Our</span> Technical Staff
              </h2>
            </div>
          </div>
        </div>

        {/* STAFF CARDS */}
        <div className="section-content">
          <div className="row">
            {technicalStaff.map((staff, index) => (
              <div key={index} className="col-md-4 col-sm-6 d-flex">
                <div className="staff-card p-a30 center box-shadow bg-white w-100">
                  <div className="icon-content">
                    <h4 className="mt-tilte text-uppercase font-weight-600 m-b10">
                      {staff.designation}
                    </h4>
                    <p className="font-weight-500">No. of Persons: {staff.persons}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTechnicalStaff;

import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../Common/default/Header';
import Footer from '../../Common/default/Footer';
import Banner from '../../Elements/default/Banner';

var bnrimg = ('/assets/media/images/banner/banner.jpg');

const filters = [
    { label: "Residential Projects", filter: ".residential" },
    { label: "Hospital & Healthcare", filter: ".healthcare" },
    { label: "Education", filter: ".education" },
    { label: "Government & Defense", filter: ".defense" },
    { label: "Industry system", filter: ".industrial" },
    { label: "Rural  Infrastructure", filter: ".rural" }
];

const projects = [
    // {
    //     image: ('/assets/media/images/projects/project-default.jpg'),
    //     title: 'LIG & MIG Houses',
    //     address: 'Suryanagar Phase – II, Bangalore,',
    //     width: 'col-md-3',
    //     filter: 'residential'
    // },
    {
        image: ('/assets/media/images/projects/residential-composite.jpg'),
        thumb: ('/assets/media/images/projects/residential-composite.jpg'),
        title: 'Composite Housing Scheme',
        address: ' Suryanagar Phase-III, Bangalore',
        width: 'col-md-3',
        filter: 'residential'
    },
    {
        image: ('/assets/media/images/projects/residential-indira-img.jpg'),
        thumb: ('/assets/media/images/projects/residential-indira-img.jpg'),
        title: 'Indira Developers, Apartments',
        address: 'Bangalore',
        width: 'col-md-3',
        filter: 'residential'
    },
    {
        image: ('/assets/media/images/projects/residential-khb.jpg'),
        thumb: ('/assets/media/images/projects/residential-khb.jpg'),
        title: 'Residential Apartment KHB-Yelahana',
        address: 'Yelahanka, Bangalore, Karnataka',
        width: 'col-md-3',
        filter: 'residential'
    },
    {
        image: ('/assets/media/images/projects/residential-canopy.jpg'),
        thumb: ('/assets/media/images/projects/residential-canopy.jpg'),
        title: 'Canopy Dwelling pvt Ltd Apartment',
        address: 'Bangalore',
        width: 'col-md-3',
        filter: 'residential'
    },
    {
        image: ('/assets/media/images/projects/residential-kpsh.jpg'),
        thumb: ('/assets/media/images/projects/residential-kpsh.jpg'),
        title: '(KSPH & IDCL, Residential & Non-Residential Buildings)',
        address: 'Various places in Karnataka.',
        width: 'col-md-3',
        filter: 'residential'
    },
    {
        image: ('/assets/media/images/projects/hospital-hscl.jpg'),
        thumb: ('/assets/media/images/projects/hospital-hscl.jpg'),
        title: 'HSCL 100 Bedded ESIC Hospitals',
        address: 'Hubbli, Davangere, and Mysore in Karnataka State.',
        width: 'col-md-3',
        filter: 'healthcare'
    },
    // {
    //     image: ('/assets/media/images/projects/project-default.jpg'),
    //     title: 'KHSDP -  Community Health Centres',
    //     address: ' Gulbarga, Mysore, Mandya, and Belgaum Districts in Karnataka.',
    //     width: 'col-md-3',
    //     filter: 'healthcare'
    // },
    // {
    //     image: ('/assets/media/images/projects/project-default.jpg'),
    //     title: '7 Permanent Social Welfare Residential School Complexes',
    //     address: 'Jaggaiahpeta, Nuzvid, Nandigama, Gudivada (Krishna District), Nizampatnam, T.Sundur (Guntur District), and Markapur (Prakasam District).',
    //     width: 'col-md-3',
    //     filter: 'education'
    // },
    {
        image: ('/assets/media/images/projects/education-rmsas-img.jpg'),
        thumb: ('/assets/media/images/projects/education-rmsas-img.jpg'),
        title: 'Rashtriya Madhyamika Sikshana Abhiyan Schools',
        address: 'Various places in Karnataka.',
        width: 'col-md-3',
        filter: 'education'
    },
    {
        image: ('/assets/media/images/projects/education-first-grade.jpg'),
        thumb: ('/assets/media/images/projects/education-first-grade.jpg'),
        title: 'Karnataka Housing Board - First Grade Colleges ',
        address: 'Various places in Karnataka.',
        width: 'col-md-3',
        filter: 'education'
    },
    // {
    //     image: ('/assets/media/images/projects/project-default.jpg'),
    //     title: 'RITES Limited Colleges',
    //     address: 'Various places in Karnataka (Package 1 & Package 2)',
    //     width: 'col-md-3',
    //     filter: 'education'
    // },
    {
        image: ('/assets/media/images/projects/education-pwd-colleges.jpg'),
        thumb: ('/assets/media/images/projects/education-pwd-colleges.jpg'),
        title: 'PWD Agricultural College Buildings ',
        address: 'Karekare, Hassan Phase – I.',
        width: 'col-md-3',
        filter: 'education'
    },
    {
        image: ('/assets/media/images/projects/gov-mes-chennai-img.jpg'),
        thumb: ('/assets/media/images/projects/gov-mes-chennai-img.jpg'),
        title: 'Advanced Training Centre Complex, Hostel-II & Mess Building ',
        address: 'National Academy of Direct Taxes, Nagpur.',
        width: 'col-md-3',
        filter: 'defense'
    },
    {
        image: ('/assets/media/images/projects/gov-nbcc.jpg'),
        thumb: ('/assets/media/images/projects/gov-nbcc.jpg'),
        title: 'NBCC Regional Hubs for National Security Guards ',
        address: 'Chennai, Hyderabad.',
        width: 'col-md-3',
        filter: 'defense'
    },
    {
        image: ('/assets/media/images/projects/gov-mess-building-img.jpg'),
        thumb: ('/assets/media/images/projects/gov-mess-building-img.jpg'),
        title: 'Military Engineer Services (MES)- Technical Building & Facilities ',
        address: 'Avadi, Chennai.',
        width: 'col-md-3',
        filter: 'defense'
    },
    {
        image: ('/assets/media/images/projects/rural-rggvy.jpg'),
        thumb: ('/assets/media/images/projects/rural-rggvy.jpg'),
        title: 'Rural Electrification works under the RGGVY ',
        address: '12th plan in Siddharthnagar District, Uttar Pradesh.',
        width: 'col-md-3',
        filter: 'rural'
    },
    {
        image: ('/assets/media/images/projects/rural-electrification.jpg'),
        thumb: ('/assets/media/images/projects/rural-electrification.jpg'),
        title: 'Madhya Pradesh Poorv Kshetra Vidyut Vitaran Co. materials, including works of a New 33/11 KV Substation.',
        address: 'Madhya Pradesh.',
        width: 'col-md-3',
        filter: 'rural'
    },
    {
        image: ('/assets/media/images/projects/project-default.jpg'),
        thumb: ('/assets/media/images/projects/project-default.jpg'),
        title: 'Industrial Estate by providing CC Roads, RCC Drains, WS & UGD Lines, Septic Tank ',
        address: ' Nelamangala, Bangalore Rural District.',
        width: 'col-md-3',
        filter: 'industrial'
    },
]
class Gallery extends React.Component {
    
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('/assets/js/masonary.js');

    };
    
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Capturing moments that showcase our journey!" pagename="Gallery" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        {/* GALLERY CONTENT START */}
                        <div className="container-fluid">
                            {/* PAGINATION START */}
                            <div className="filter-wrap p-b30 text-center">
                                <ul className="filter-navigation inline-navigation masonry-filter link-style  text-uppercase">
                                    <li className="active"><NavLink to={"#"} data-filter="*" data-hover="All">All</NavLink></li>
                                    {filters.map((item, index) => (
                                    <li key={index}><NavLink to={"#"} data-filter={item.filter} data-hover={item.label}>{item.label}</NavLink></li>
                                    ))}
                                </ul>
                            </div>
                            {/* PAGINATION END */}
                            {/* GALLERY CONTENT START */}
                            <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                                {projects.map((item, index) => (
                                    <div key={index} className={`${item.filter} masonry-item col-lg-3  col-md-4 col-sm-6 m-b30`}>
                                        <div className="image-effect-one hover-shadow">
                                            <img src={item.thumb} alt="" />
                                            <div className="figcaption">
                                                <h4>{item.title}</h4>
                                                <p>{item.description}</p>
                                                <NavLink to="/"><i className="link-plus bg-primary" /></NavLink>
                                            </div>
                                            <a className="mfp-link" href={item.image}>
                                                <i className="fa fa-arrows-alt" />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                               
                            </div>
                            {/* GALLERY CONTENT END */}
                            <div className="m-t50 text-center">
                                <NavLink to={"#"} className="site-button btn-effect">Load More</NavLink>
                            </div>
                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>

                <Footer />
            </>
        );
    };
};

export default Gallery;
import React from "react";
import { NavLink } from "react-router-dom";

class AboutCompany extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 bg-white">
          <div className="container">
            <div className="section-content ">
              <div className="m-service-containt text-black">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <div className="service-about-left">
                      <div className="mt-media">
                        <img src={"/assets/media/images/clientele-img.jpg"} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-12">
                    <div className="service-about-right m-b30">
                      <h3 className="m-t0">
                        The Story of Jampana Constructions
                      </h3>
                      <p>
                        Since its founding in 2003, Jampana Constructions has
                        grown from a small venture to a renowned name in the
                        construction industry, with a legacy of delivering
                        innovative and high-quality projects that continue to
                        shape communities and industries.
                      </p>
                      <div className="call-for-quote-outer">
                        <div className="call-quote">
                          <span>Call For a Quote:</span>
                          <h4>(+91) 80 7129 0107</h4>
                        </div>
                        <div className="call-estimate bg-dark">
                          <NavLink
                            to={"#"}
                            className="site-button-secondry btn-effect"
                          >
                            Online Estimate Form
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutCompany;

import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../Common/default/Header';
import Footer from '../../../Common/default/Footer';
import Banner from '../../../Elements/default/Banner';
import OurProject3 from '../../../Elements/default/OurProject3';
import Faq from '../../../Elements/default/Faq';
import OurValues from '../../../Elements/default/OurValues';
import WorkCompletedAbout from '../../../Elements/default/work-completed/WorkCompletedAbout';
import WorkCompletedProjects from '../../../Elements/default/work-completed/WorkCompletedProjects';
import WorkCompletedCardNav from '../../../Elements/default/work-completed/WorkCompletedCardNav';
import { workData } from '../../../Constants/workData';

const bnrimg = '/assets/media/images/banner/banner.jpg';

const WorkCompleted = () => {
    const { sectionId } = useParams();

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner 
                    title="Diversified services. Unvarying quality." 
                    pagename="Works Completed" 
                    bgimage={bnrimg} 
                />
                <WorkCompletedAbout sectionId={sectionId} cat={workData[sectionId]?.cat} />
                <WorkCompletedProjects sectionId={sectionId} />
                <WorkCompletedCardNav sectionId={sectionId} />
                <OurValues />
                <OurProject3 />
                <Faq  />
                <Footer />
            </div>
        </>
    );
};

export default WorkCompleted;
